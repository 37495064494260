<template>
  <v-container>
    <select-project v-model="project"></select-project>
    <h1>Keuringsfrequenties</h1>
    <v-row>
      <v-col>
        <v-text-field
          v-model="search"
          label="Zoek in frequenties"
          class="mx-4"
          prepend-icon="mdi-magnify"
          single-line
        ></v-text-field>
        <v-data-table
          v-if="this.project"
          dense
          :headers="headers"
          :items="frequenties"
          :hide-default-footer="true"
          disable-pagination
          class="elevation-1"
          sort-by="OrderIndex"
          :search="search"
          @click:row="edit"
        >
          <template v-slot:[`item.Start`]="{ item }">
            <span v-if="item.Start">{{ item.Start | moment("DD-MM-YYYY") }}</span>
          </template>
          <template v-slot:[`item.Eind`]="{ item }">
            <span v-if="item.Einde">{{ item.Einde | moment("DD-MM-YYYY") }}</span>
          </template>
          <template v-slot:[`item.Inserted`]="{ item }">
            {{ item.Inserted | moment("DD-MM-YYYY HH:mm") }}
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-btn
              icon
              @click.stop="deleteFrequentie(item)"
              :title="
                item.CanDelete
                  ? 'Verwijder frequentie'
                  : 'Verwijderen niet mogelijk, keuring wordt al uitgevoerd.'
              "
              v-show="item.CanDelete"
              ><v-icon>mdi-delete-outline</v-icon></v-btn
            >
          </template>
        </v-data-table>
        <v-btn
          fab
          title="Voeg nieuwe keuringsfrequentie toe"
          @click="add()"
          color="primary"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SelectProject from "@/components/selectproject.vue";
export default {
  name: "BeheerFrequenties",
  components: {
    SelectProject,
  },
  data: () => ({
    frequenties: [],
    project: null,
    search: "",
    headers: [
      { text: "Partij", value: "Partij" },
      { text: "Keuring", value: "Keuring" },
      { text: "Keuringstype", value: "Keuringstype" },
      { text: "Categorie", value: "Categorie" },
      { text: "Frequentie", value: "Frequentie" },
      { text: "Status", value: "Status" },
      { text: "Start", value: "Start" },
      { text: "Eind", value: "Eind" },
      { text: "Ingevoerd", value: "Inserted" },
      { text: "Volgnr", value: "OrderIndex" },
      { text: "", value: "Actions", sortable: false },
    ],
  }),
  methods: {
    loadData: function () {},
    planningRefresh: function () {
      if (this.project) {
        this.$api
          .Planning_pKeuringsfrequentiesGet({ ProjectId: this.project.Id })
          .then((response) => {
            this.frequenties = response.data;
          });
      }
    },
    edit: function (f) {
      this.$router.push({
        name: "beheerfrequentieedit",
        params: { id: f.Id },
      });
    },
    add: function () {
      this.$router.push({
        name: "beheerfrequentienieuw",
      });
    },
    deleteFrequentie: function (p) {
      this.$confirm("Weet je zeker dat je deze keuringsfrequentie wilt verwijderen?", {
        title: "Verwijderen",
      }).then((res) => {
        if (res) {
          this.$api.Planning_pKeuringsfrequentieDelete({ PlanningId: p.Id }).then(() => {
            this.$cmdapi.BlokvisRecalc({ async: true });
            this.planningRefresh();
          });
        }
      });
    },
    setColVis: function () {
      this.headers[2].align = this.project.KeuringstypeEnabled ? "" : " d-none";
    },
  },
  watch: {
    project: function () {
      this.planningRefresh();
      this.setColVis();
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
