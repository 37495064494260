<template>
  <v-container>
    <select-project v-model="project"></select-project>
    <h1>Bulkinvoer uitvoeringen</h1>
    <v-form v-model="valid" ref="form">
      <v-row>
        <v-col md="6">
          <v-select
            v-model="fixedvalues.PartijId"
            :items="partijen"
            label="Partij"
            item-text="Naam"
            item-value="Id"
            :rules="[(v) => !!v || 'Selecteer een partij']"
            required
          ></v-select>
          <v-select
            v-show="project && project.KeurderEnabled"
            v-model="fixedvalues.KeurderId"
            :items="keurders"
            label="Keurder"
            item-text="Naam"
            item-value="Id"
            clearable
          ></v-select>
          <v-select
            v-model="fixedvalues.KeuringId"
            :items="keuringen"
            label="Keuring"
            item-text="Keuring"
            item-value="Id"
            :rules="[(v) => !!v || 'Selecteer een keuring']"
            no-data-text="Selecteer een partij met keuringen."
            required
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <blockquote class="blockquote">
            Vul hieronder de datum en de opmerkingen in. <br />
            <ul>
              <li>Eén datum + opmerking per regel.</li>
              <li>Gebruik een ; als scheiding tussen datum en opmerking.</li>
              <li>Datumformaat: dd-mm-jjjj.</li>
              <li>Gebruik \; als je een ; in de opmerking wilt gebruiken.</li>
              <li>
                De uitvoeringen worden aangemaakt voor hierboven geselecteerde project,
                frequentie en partij
              </li>
            </ul>
          </blockquote>
          <v-textarea
            v-model="bulkinvoer"
            label="Vul hier de datum en opmerkingen van keuringen in"
            outlined
            class="mt-5"
            height="250"
            :rules="[(v) => !!v || 'Voer keuringen in']"
            required
          ></v-textarea>
          <v-btn @click="save()" color="primary"
            ><v-icon left>mdi-content-save-outline</v-icon>Opslaan</v-btn
          ><v-btn class="ml-2" :to="{ name: 'uitgevoerdekeuringen' }"
            ><v-icon left>mdi-close</v-icon>Terug</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="error.show" top light elevation="0">
      <v-alert outlined type="error" text>{{ error.message }}</v-alert>
    </v-snackbar>
    <v-snackbar v-model="message.show" top light elevation="0">
      <v-alert outlined type="success" text>{{ message.message }}</v-alert>
    </v-snackbar>
  </v-container>
</template>

<script>
import SelectProject from "@/components/selectproject.vue";

export default {
  name: "UitvoeringBulk",
  components: {
    SelectProject,
  },
  data: () => ({
    project: null,
    uitvoering: null,
    valid: false,
    partijen: [],
    keuringen: [],
    kenmerken: [],
    keurders: [],
    fixedvalues: {
      PartijId: null,
      KeurderId: null,
      Datum: new Date(),
      KeuringId: null,
    },
    bulkinvoer: "",
    error: {
      show: false,
      message: "",
    },
    message: {
      show: false,
      message: "",
    },
  }),
  methods: {
    loadData: function () {},
    getPartijen: function () {
      this.$api
        .pPartijenGet({ ProjectId: this.project.Id, AlleenMetPlanning: true })
        .then((response) => {
          this.partijen = response.data;
        });
    },
    refreshKeurders: function () {
      if (this.fixedvalues) {
        this.$api
          .pKeurdersGet({ PartijId: this.fixedvalues.PartijId })
          .then((response) => {
            this.keurders = response.data;
          });
      }
    },
    getKeuringen: function () {
      if (this.project != null) {
        this.$api
          .Planning_pKeuringenGet({
            ProjectId: this.project.Id,
            PartijId: this.fixedvalues.PartijId,
          })
          .then((response) => {
            this.keuringen = response.data;
          });
      }
    },
    save: function () {
      if (this.$refs.form.validate()) {
        var param = {
          PartijId: this.fixedvalues.PartijId,
          KeurderId: this.fixedvalues.KeurderId,
          DatumUitvoering: this.fixedvalues.Datum,
          PlanningId: this.fixedvalues.KeuringId,
          Bulkinvoer: this.bulkinvoer,
        };
        this.$api.Uitvoering_pBulkInvoerCheck(param).then((response) => {
          if (response.data[0].Success) {
            // Invoer is Ok => doorvoeren
            this.$api.Uitvoering_pBulkInvoer(param).then((response) => {
              if (response.data[0].Success) {
                // Gelukt!
                this.message.message = "De uitvoeringen zijn opgeslagen.";
                this.message.show = true;
                this.bulkinvoer = "";
              } else {
                // Opslaan toch niet gelukt (Vreemd de check was wel ok)
                this.error.message = "Fout bij opslaan";
                this.error.show = true;
              }
              this.$refs.form.resetValidation();
            });
          } else {
            // Check niet ok.
            this.bulkinvoer = response.data[0].Result;
            this.error.message =
              "Opslaan is mislukt. De foute regels zijn gemarkeerd. Er is niets opgeslagen.";
            this.error.show = true;
          }
        });
      }
    },
  },
  watch: {
    project: function () {
      this.getPartijen();
    },
    "fixedvalues.PartijId": function () {
      this.refreshKeurders();
      this.getKeuringen();
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
