<template>
  <v-container>
    <h1>Projectoverzicht</h1>

    <v-row>
      <v-col cols="9">
        <v-progress-linear indeterminate v-show="loading" class="mt-4 mb-6"></v-progress-linear>
        <GrafiekProjectCmp ref="grafiek"></GrafiekProjectCmp>
      </v-col>
      <v-col cols="3">
        <h2>Periode</h2>
        <v-radio-group v-model="rapportperiodeId">
          <v-radio
            v-for="(rp, i) in rapportperiodes"
            :key="i"
            :value="rp.Id"
            :label="rp.Naam"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GrafiekProjectCmp from "@/charts/grafiekprojectcmp.vue";

export default {
  name: "GrafiekProject",
  components: {
    GrafiekProjectCmp,
  },
  data: () => ({ rapportperiodes: [], rapportperiodeId: 1, loading: false }),
  methods: {
    loadData: function () {
      this.$api.pRapportPeriodeGet().then((response) => {
        this.rapportperiodes = response.data;
      });
      this.refreshChart();
    },
    refreshChart: function () {
        this.loading = true;
      this.$api
        .Grafiek_pGrafiekProjectenGet({ RapportPeriodeId: this.rapportperiodeId })
        .then((response) => {
          this.convertData(response.data);
          this.loading = false;
        });
    },
    convertData: function (rawdata) {
      this.$refs.grafiek.convertData(rawdata);
    },
  },
  computed: {},
  watch: {
    rapportperiodeId: function () {
      this.refreshChart();
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
</style>
