<template>
  <v-container>
    <v-row class="justify-center">
      <v-img src="/keuringsmonitor_logo.png" max-width="600" contain class="mt-10 mb-10">
      </v-img>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Welkom bij de keuringsmonitor. Hier kun je keuringen plannen en vastleggen. Je
          houdt overzicht over het keuringsproces en kunt de voortgang bewaken.
        </p>
        <p>Je kunt een keuze maken in het menu aan de linker kant.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({}),
};
</script>
