/*
    Generated file
    Do not alter.
    16-09-2021 22:25:05
 */
import axios from 'axios';

export default function ApiHandler(apiUrl) {
    let baseurl = "";
    if (process.env.NODE_ENV == "development")
        baseurl = 'http://localhost:8080/api/Sproc/';
    else
        baseurl = '/api/api/Sproc/';

    this.axios = axios;
    this.apiUrl = apiUrl || baseurl;
    this.config = {
        headers: { "content-type": "application/json" },
        baseURL: this.apiUrl,
        withCredentials: true,
    };
    this.configBlob = {
        headers: { "content-type": "application/json" },
        baseURL: this.apiUrl,
        withCredentials: true,
        responseType: 'blob',
    };
}


    // pAppInfo
    ApiHandler.prototype.pAppInfo = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('sspAppInfo' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // pCategorieenGet
    ApiHandler.prototype.pCategorieenGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('sspCategorieenGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // pKenmerkenGet
    ApiHandler.prototype.pKenmerkenGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('sspKenmerkenGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // pKeurdersGet
    ApiHandler.prototype.pKeurdersGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('sspKeurdersGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // pKeuringstypesGet
    ApiHandler.prototype.pKeuringstypesGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('sspKeuringstypesGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // pPartijenGet
    ApiHandler.prototype.pPartijenGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('sspPartijenGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // pPartijGet
    ApiHandler.prototype.pPartijGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('sspPartijGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // pProjectenGet
    ApiHandler.prototype.pProjectenGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('sspProjectenGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // pRapportPeriodeGet
    ApiHandler.prototype.pRapportPeriodeGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('sspRapportPeriodeGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // pStatusGet
    ApiHandler.prototype.pStatusGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('sspStatusGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // pVakantietypeGet
    ApiHandler.prototype.pVakantietypeGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('sspVakantietypeGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Blokvis_pBlokvisGetCalcDate
    ApiHandler.prototype.Blokvis_pBlokvisGetCalcDate = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssBlokvis_pBlokvisGetCalcDate' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Blokvis_pBlokvisGetData
    ApiHandler.prototype.Blokvis_pBlokvisGetData = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssBlokvis_pBlokvisGetData' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Blokvis_pBlokvisGetProjectPeriode
    ApiHandler.prototype.Blokvis_pBlokvisGetProjectPeriode = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssBlokvis_pBlokvisGetProjectPeriode' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Blokvis_pBlokvisGetWeken
    ApiHandler.prototype.Blokvis_pBlokvisGetWeken = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssBlokvis_pBlokvisGetWeken' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Blokvis_pBlokvisLegenda
    ApiHandler.prototype.Blokvis_pBlokvisLegenda = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssBlokvis_pBlokvisLegenda' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Grafiek_pGrafiekProjectenGet
    ApiHandler.prototype.Grafiek_pGrafiekProjectenGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssGrafiek_pGrafiekProjectenGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Grafiek_pGrafiekUitgevoerdeKeuringen
    ApiHandler.prototype.Grafiek_pGrafiekUitgevoerdeKeuringen = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssGrafiek_pGrafiekUitgevoerdeKeuringen' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Grafiek_pGrafiekUitgevoerdeKeuringenPerKenmerk
    ApiHandler.prototype.Grafiek_pGrafiekUitgevoerdeKeuringenPerKenmerk = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssGrafiek_pGrafiekUitgevoerdeKeuringenPerKenmerk' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Grafiek_pGrafiekUitgevoerdeKeuringenPerKeuringstype
    ApiHandler.prototype.Grafiek_pGrafiekUitgevoerdeKeuringenPerKeuringstype = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssGrafiek_pGrafiekUitgevoerdeKeuringenPerKeuringstype' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Planning_pFrequentietypeGet
    ApiHandler.prototype.Planning_pFrequentietypeGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssPlanning_pFrequentietypeGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Planning_pKeuringenGet
    ApiHandler.prototype.Planning_pKeuringenGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssPlanning_pKeuringenGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Planning_pKeuringFrequentieNew
    ApiHandler.prototype.Planning_pKeuringFrequentieNew = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssPlanning_pKeuringFrequentieNew' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Planning_pKeuringsfrequentieDelete
    ApiHandler.prototype.Planning_pKeuringsfrequentieDelete = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssPlanning_pKeuringsfrequentieDelete' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Planning_pKeuringsfrequentieGet
    ApiHandler.prototype.Planning_pKeuringsfrequentieGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssPlanning_pKeuringsfrequentieGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Planning_pKeuringsfrequentiesGet
    ApiHandler.prototype.Planning_pKeuringsfrequentiesGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssPlanning_pKeuringsfrequentiesGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Planning_pKeuringsfrequentiesSave
    ApiHandler.prototype.Planning_pKeuringsfrequentiesSave = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssPlanning_pKeuringsfrequentiesSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Projectbeheer_pPartijDelete
    ApiHandler.prototype.Projectbeheer_pPartijDelete = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssProjectbeheer_pPartijDelete' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Projectbeheer_pPartijSave
    ApiHandler.prototype.Projectbeheer_pPartijSave = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssProjectbeheer_pPartijSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Projectbeheer_pProjectCategorieenGet
    ApiHandler.prototype.Projectbeheer_pProjectCategorieenGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssProjectbeheer_pProjectCategorieenGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Projectbeheer_pProjectCategorieenNieuw
    ApiHandler.prototype.Projectbeheer_pProjectCategorieenNieuw = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssProjectbeheer_pProjectCategorieenNieuw' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Projectbeheer_pProjectCategorieenSave
    ApiHandler.prototype.Projectbeheer_pProjectCategorieenSave = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssProjectbeheer_pProjectCategorieenSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Projectbeheer_pProjectenBeheerGet
    ApiHandler.prototype.Projectbeheer_pProjectenBeheerGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssProjectbeheer_pProjectenBeheerGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Projectbeheer_pProjectGet
    ApiHandler.prototype.Projectbeheer_pProjectGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssProjectbeheer_pProjectGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Projectbeheer_pProjectNieuw
    ApiHandler.prototype.Projectbeheer_pProjectNieuw = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssProjectbeheer_pProjectNieuw' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Projectbeheer_pProjectSave
    ApiHandler.prototype.Projectbeheer_pProjectSave = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssProjectbeheer_pProjectSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Rapport_pDefaultParamsGet
    ApiHandler.prototype.Rapport_pDefaultParamsGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssRapport_pDefaultParamsGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Rapport_pGenereerRapport
    ApiHandler.prototype.Rapport_pGenereerRapport = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssRapport_pGenereerRapport' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Rapport_pGetBlokvis
    ApiHandler.prototype.Rapport_pGetBlokvis = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssRapport_pGetBlokvis' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Rapport_pGetGrafiekPerPartij
    ApiHandler.prototype.Rapport_pGetGrafiekPerPartij = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssRapport_pGetGrafiekPerPartij' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Rapport_pGetGrafiekTotaal
    ApiHandler.prototype.Rapport_pGetGrafiekTotaal = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssRapport_pGetGrafiekTotaal' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Rapport_pGetHeaderInfo
    ApiHandler.prototype.Rapport_pGetHeaderInfo = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssRapport_pGetHeaderInfo' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Rapport_pGetKeuringenPeriode
    ApiHandler.prototype.Rapport_pGetKeuringenPeriode = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssRapport_pGetKeuringenPeriode' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Rapport_pGetKeuringenPerPartij
    ApiHandler.prototype.Rapport_pGetKeuringenPerPartij = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssRapport_pGetKeuringenPerPartij' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Rapport_pGetLogos
    ApiHandler.prototype.Rapport_pGetLogos = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssRapport_pGetLogos' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Rapport_pGetParams
    ApiHandler.prototype.Rapport_pGetParams = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssRapport_pGetParams' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Rapport_pMailRapport
    ApiHandler.prototype.Rapport_pMailRapport = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssRapport_pMailRapport' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Rapport_pOpschonenRapportages
    ApiHandler.prototype.Rapport_pOpschonenRapportages = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssRapport_pOpschonenRapportages' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Rapport_pSaveParams
    ApiHandler.prototype.Rapport_pSaveParams = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssRapport_pSaveParams' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Uitvoering_pBulkInvoer
    ApiHandler.prototype.Uitvoering_pBulkInvoer = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssUitvoering_pBulkInvoer' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Uitvoering_pBulkInvoerCheck
    ApiHandler.prototype.Uitvoering_pBulkInvoerCheck = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssUitvoering_pBulkInvoerCheck' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Uitvoering_pUitgevoerdeKeuringDelete
    ApiHandler.prototype.Uitvoering_pUitgevoerdeKeuringDelete = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssUitvoering_pUitgevoerdeKeuringDelete' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Uitvoering_pUitgevoerdeKeuringenGet
    ApiHandler.prototype.Uitvoering_pUitgevoerdeKeuringenGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssUitvoering_pUitgevoerdeKeuringenGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Uitvoering_pUitgevoerdeKeuringenSave
    ApiHandler.prototype.Uitvoering_pUitgevoerdeKeuringenSave = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssUitvoering_pUitgevoerdeKeuringenSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Uitvoering_pUitgevoerdeKeuringGet
    ApiHandler.prototype.Uitvoering_pUitgevoerdeKeuringGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('ssUitvoering_pUitgevoerdeKeuringGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Login
    ApiHandler.prototype.Login = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('Login' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Logout
    ApiHandler.prototype.Logout = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('pUserLogout' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // RequestPasswordReset
    ApiHandler.prototype.RequestPasswordReset = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('RequestPasswordReset' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // PasswordReset
    ApiHandler.prototype.PasswordReset = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('PasswordReset' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // Authenticated
    ApiHandler.prototype.Authenticated = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('pAuthenticated' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // ValidationRulesGet
    ApiHandler.prototype.ValidationRulesGet = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('pValidationRulesGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // UserGetList
    ApiHandler.prototype.UserGetList = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('pUserGetList' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // UserInitNew
    ApiHandler.prototype.UserInitNew = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('pUserInitNew' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // UserGetDetails
    ApiHandler.prototype.UserGetDetails = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('pUserGetDetails' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // UserSave
    ApiHandler.prototype.UserSave = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('pUserSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // UserValidate
    ApiHandler.prototype.UserValidate = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('pUserValidate' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }

    // NewUserMail
    ApiHandler.prototype.NewUserMail = function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = null;
            if (frmt) { frmt = "?format=" + frmt; config = this.configBlob; } else { frmt = ""; config = this.config; }
            this.axios.post('NewUserMail' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
        });
    }



    // Download
    ApiHandler.prototype.saveDownload = function(data, filename) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    }