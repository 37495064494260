<template>
  <div>
    <div v-for="(l, i) in legenda" :key="i" class="blokvisrow">
      <span class="weekcell">
        <span
          class="blok"
          :style="{
            'background-color': l.Kleurcode,
            'border-color': l.KleurcodeBorder,
          }"
        ></span>
      </span>
      <span class="rijlabel">{{ l.Omschrijving }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlokvisLegenda",
  components: {},
  data: () => ({
    legenda: [],
  }),
  methods: {
    loadData: function () {
      this.$api.Blokvis_pBlokvisLegenda().then((response) => {
        this.legenda = response.data;
      });
    },
  },
  watch: {},
  computed: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.blokvissectie {
  margin-bottom: 10px;
}
.blokvissectieheader {
  background-color: #d9d9d9;
  text-align: center;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
}

.blokvisrow {
  font-size: 12px;
  height: 30px;
}

.weekas {
  background-color: #fff;
}

.weekas .weekcell {
  background-color: #fff;
  cursor: pointer;
  border-right: 1px solid #ebebeb;
}

.weekcell {
  display: inline-block;
  width: 30px;
  text-align: center;
  height: 100%;
  overflow: hidden;
  line-height: 35px;
  background-color: #ebebeb;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}

.rijlabel {
  display: inline-block;
  text-align: left;
  width: 30%;
  overflow: hidden;
  height: 100%;
  line-height: 30px;
  background-color: #fff;
  padding-right: 5px;
  padding-left: 5px;
}
.rijcontent {
  display: inline-block;
  width: 70%;
  height: 100%;
}

.blok {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid black;
  cursor: pointer;
  border-width: 2px;
}

.calcdate {
  font-size: 9pt;
  color: #c0c0c0;
}
</style>
