import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import ApiHandler from './ApiHandler'
import CmdApiHandler from './CmdApiHandler'
import './filters/currency'
import VuetifyConfirm from 'vuetify-confirm'
import Dayjs from 'vue-dayjs';

Vue.config.productionTip = false
Vue.prototype.$api = new ApiHandler();
Vue.prototype.$cmdapi = new CmdApiHandler();
Vue.use(require('vue-moment'));

Vue.use(Dayjs, {
  lang:  'nl',
  filters: {
    ago: 'ago',
  },
  directives: {
    countdown: 'countdown',
  }
});

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Ja',
  buttonFalseText: 'Nee',
  color: 'info',
  width: 350,
  property: '$confirm'
});

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
