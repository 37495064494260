<template>
  <v-container>
    <select-project v-model="project" v-show="false"></select-project>
    <h1 v-show="!frequentie || !frequentie.Id">Nieuwe keuringsfrequentie</h1>
    <h1 v-show="frequentie && frequentie.Id">Keuringsfrequentie bewerken</h1>
    <v-card v-if="frequentie">
      <v-card-title>Keuringsfrequentie</v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-select
            v-model="frequentie.PartijId"
            :items="partijen"
            label="Partij"
            item-text="Naam"
            item-value="Id"
            :rules="[(v) => !!v || 'Selecteer een partij']"
            required
          >
          </v-select>

          <v-text-field
            label="Keuring"
            maxlength="200"
            v-model="frequentie.Keuring"
            :rules="[(v) => !!v || 'Keuringsnaam is verplicht.']"
            required
          ></v-text-field>

          <v-select
            v-model="frequentie.KeuringstypeId"
            :items="keuringstypes"
            label="Keuringstype"
            item-text="Naam"
            item-value="Id"
            clearable
            v-show="project && project.KeuringstypeEnabled"
          >
          </v-select>

          <v-select
            v-model="frequentie.CategorieId"
            :items="categorieen"
            label="Categorie"
            item-text="Naam"
            item-value="Id"
          >
          </v-select>
          <v-text-field
            label="Volgnummer"
            maxlength="5"
            v-model="frequentie.OrderIndex"
            :rules="[(v) => !!v || 'Volgnummer is verplicht.']"
            required
            type="number"
          ></v-text-field>
          <label>Frequentie</label>
          <v-row>
            <v-col>
              <v-radio-group v-model="selectedfrequentie">
                <v-radio
                  v-for="(ft, i) in frequentietypes"
                  :key="i"
                  :label="ft.Naam"
                  :value="ft"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <v-radio-group
                v-model="frequentie.FrequentieSubTypeId"
                v-if="
                  selectedfrequentie &&
                  selectedfrequentie.Id != 4 &&
                  selectedfrequentie.Id != 5
                "
              >
                <v-radio
                  v-for="(ft, i) in selectedfrequentie.Subtype"
                  :key="i"
                  :label="ft.Naam"
                  :value="ft.Id"
                ></v-radio
              ></v-radio-group>
            </v-col>
            <v-col>
              <v-text-field
                v-model="frequentie.Aantal"
                label="Aantal"
                maxlength="10"
                v-show="showAantal()"
                :rules="aantalRules"
                :required="showAantal()"
              ></v-text-field>
            </v-col>
          </v-row>
          <div v-show="!isVrijeKeuring">
            <label>Status</label>
            <v-radio-group v-model="frequentie.StatusId">
              <v-radio
                v-for="(s, i) in statussen"
                :key="i"
                :label="s.Naam"
                :value="s.Id"
              ></v-radio>
            </v-radio-group>

            <label>Looptijd</label>
            <br />
            <date-picker v-model="frequentie.Start" label="Startdatum"></date-picker>
            <date-picker v-model="frequentie.Einde" label="Einddatum"></date-picker>
            <span style="color:red" v-show="this.frequentie.Start > this.frequentie.Einde">Einddatum valt voor startdatum</span>
            <label v-if="false">Vakanties/keuringsloze periodes</label>
            <v-radio-group v-model="frequentie.VakantieTypeId" v-if="false">
              <v-radio
                v-for="(vt, i) in vakantietypes"
                :key="i"
                :label="vt.Naam"
                :value="vt.Id"
              ></v-radio
            ></v-radio-group>
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="saveFrequentie()" color="primary">Opslaan</v-btn>
        <v-btn @click="sluiten()">Annuleren</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import SelectProject from "@/components/selectproject.vue";
import DatePicker from "@/components/date-picker.vue";

export default {
  name: "BeheerFrequentie",
  components: {
    SelectProject,
    DatePicker,
  },
  data: () => ({
    frequentie: null,
    partijen: [],
    categorieen: [],
    frequentietypes: [],
    selectedfrequentie: null,
    statussen: [],
    vakantietypes: [],
    project: null,
    valid: false,
    keuringstypes: [],
  }),
  methods: {
    loadData: function () {
      this.$api
        .pPartijenGet({
          ProjectId: localStorage.getItem("ProjectId"),
          AlleenMetPlanning: false,
        })
        .then((response) => {
          this.partijen = response.data;
        });

      this.$api
        .pCategorieenGet({ ProjectId: localStorage.getItem("ProjectId") })
        .then((response) => {
          this.categorieen = response.data;
        });

      this.$api.pStatusGet().then((response) => {
        this.statussen = response.data;
      });

      this.$api.pKeuringstypesGet().then((response) => {
        this.keuringstypes = response.data;
      });

      this.$api.Planning_pFrequentietypeGet().then((response) => {
        this.frequentietypes = response.data;

        if (this.$route.params.id) {
          this.$api
            .Planning_pKeuringsfrequentieGet({ PlanningId: this.$route.params.id })
            .then((response) => {
              this.frequentie = response.data[0];
              this.selectedfrequentie = this.frequentietypes[
                this.frequentie.FrequentieTypeId - 1
              ];
            });
        } else {
          this.initNewKeuring();
        }
      });

      this.$api.pVakantietypeGet().then((response) => {
        this.vakantietypes = response.data;
      });
    },
    initNewKeuring: function () {
      if (this.project != null) {
        this.$api
          .Planning_pKeuringFrequentieNew({ ProjectId: this.project.Id })
          .then((response) => {
            this.frequentie = response.data[0];

            this.selectedfrequentie = this.frequentietypes[
              this.frequentie.FrequentieTypeId - 1
            ];
          });
      }
    },
    showAantal: function () {
      if (this.frequentie.FrequentieTypeId == 4) return true;
      if (
        this.frequentie.FrequentieSubTypeId == 2 ||
        this.frequentie.FrequentieSubTypeId == 3 ||
        this.frequentie.FrequentieSubTypeId == 5 ||
        this.frequentie.FrequentieSubTypeId == 6 ||
        this.frequentie.FrequentieSubTypeId == 8 ||
        this.frequentie.FrequentieSubTypeId == 9
      )
        return true;
      return false;
    },
    saveFrequentie: function () {
      if (this.$refs.form.validate()) {

        // Controleer einddatum >= startdatum
        if (this.frequentie.Start > this.frequentie.Einde)
        {
            return;
        }

        this.$api.Planning_pKeuringsfrequentiesSave(this.frequentie).then(() => {
          this.$cmdapi.BlokvisRecalc({ async: true });
          this.sluiten();
        });
      }
    },
    sluiten: function () {
      this.$router.push({
        name: "beheerfrequenties",
      });
    },
    subtypeOk: function (frequentie, subtypeId) {
      var res = false;
      for (var i = 0; i < frequentie.Subtype.length; i++) {
        if (frequentie.Subtype[i].Id == subtypeId) return true;
      }
      return res;
    },
  },
  watch: {
    selectedfrequentie: function () {
      this.frequentie.FrequentieTypeId = this.selectedfrequentie.Id;
      if (this.selectedfrequentie.Subtype.length > 0) {
        if (
          !this.subtypeOk(this.selectedfrequentie, this.frequentie.FrequentieSubTypeId)
        ) {
          this.frequentie.FrequentieSubTypeId = this.selectedfrequentie.Subtype[0].Id;
          this.frequentie.Aantal = 1;
        }
      } else {
        this.frequentie.FrequentieSubTypeId = null;
      }
    },
    "frequentie.FrequentieSubTypeId": function (newvalue, oldvalue) {
      if (oldvalue != null && newvalue != null) this.frequentie.Aantal = 1;
    },
    project: function (newvalue, oldvalue) {
      if (!this.$route.params.id && oldvalue == null && newvalue != null)
        this.initNewKeuring();
    },
  },
  computed: {
    aantalRules() {
      const rules = [];

      if (this.showAantal()) {
        const rule = (v) => !!v || "Aantal is verplicht";
        rules.push(rule);
      }

      return rules;
    },
    isVrijeKeuring() {
      if (!this.selectedfrequentie) return false;
      return this.selectedfrequentie.Id == 5;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
