<template>
  <v-container>
    <select-project v-model="project" v-show="false"></select-project>
    <h1>Uitgevoerde keuring bewerken</h1>

    <v-card v-if="uitvoering">
      <v-card-title>Uitgevoerde keuring</v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-select
            v-model="uitvoering.PartijId"
            :items="partijen"
            label="Partij"
            item-text="Naam"
            item-value="Id"
            :rules="[(v) => !!v || 'Selecteer een partij']"
            required
          ></v-select>
          <v-select
            v-model="uitvoering.KeurderId"
            :items="keurders"
            label="Keurder"
            item-text="Naam"
            item-value="Id"
            clearable
            v-show="project && project.KeurderEnabled"
          ></v-select>
          <v-select
            v-model="uitvoering.PlanningId"
            :items="keuringen"
            label="Keuring"
            item-text="Keuring"
            item-value="Id"
            :rules="[(v) => !!v || 'Selecteer een keuring']"
            no-data-text="Selecteer een partij met keuringen."
            required
          ></v-select>
          <date-picker v-model="uitvoering.Datum" label="Datum"></date-picker>
          <v-select
            v-model="uitvoering.KenmerkId"
            v-show="project && project.KenmerkEnabled"
            :items="kenmerken"
            label="Kenmerk"
            item-text="Naam"
            item-value="Id"
            :rules="[(v) => !!v || 'Selecteer een kenmerk']"
          ></v-select>

          <v-text-field
            v-model="uitvoering.Opmerking"
            maxlength="200"
            label="Opmerkingen"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="saveUitvoering()" color="primary">Opslaan</v-btn>
        <v-btn @click="sluiten()">Annuleren</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import SelectProject from "@/components/selectproject.vue";
import DatePicker from "@/components/date-picker.vue";

export default {
  name: "UitgevoerdeKeuring",
  components: {
    DatePicker,
    SelectProject,
  },
  data: () => ({
    project: null,
    uitvoering: null,
    valid: false,
    partijen: [],
    keuringen: [],
    kenmerken: [],
    keurders: [],
  }),
  methods: {
    loadData: function () {
      this.$api
        .Uitvoering_pUitgevoerdeKeuringGet({ UitvoeringId: this.$route.params.id })
        .then((response) => {
          this.uitvoering = response.data[0];

          this.$api
            .pPartijenGet({ ProjectId: localStorage.getItem("ProjectId") })
            .then((response) => {
              this.partijen = response.data;
            });
          this.$api.pKenmerkenGet().then((response) => {
            this.kenmerken = response.data;
          });
        });
    },
    getKeuringen: function () {
      if (this.project != null) {
        this.$api
          .Planning_pKeuringenGet({
            ProjectId: localStorage.getItem("ProjectId"),
            PartijId: this.uitvoering.PartijId,
          })
          .then((response) => {
            this.keuringen = response.data;
          });
      }
    },
    refreshKeurders: function () {
      if (this.uitvoering) {
        this.$api
          .pKeurdersGet({ PartijId: this.uitvoering.PartijId })
          .then((response) => {
            this.keurders = response.data;
          });
      }
    },
    saveUitvoering: function () {
      if (this.$refs.form.validate()) {
        this.$api
          .Uitvoering_pUitgevoerdeKeuringenSave({
            UitvoeringId: this.uitvoering.Id,
            PlanningId: this.uitvoering.PlanningId,
            Datum: this.uitvoering.Datum,
            PartijId: this.uitvoering.PartijId,
            KenmerkId: this.uitvoering.KenmerkId,
            Opmerking: this.uitvoering.Opmerking,
            KeurderId: this.uitvoering.KeurderId,
          })
          .then(() => {
            this.$cmdapi.BlokvisRecalc({ async: true });
            this.sluiten();
          });
      }
    },
    sluiten: function () {
      this.$router.push({
        name: "uitgevoerdekeuringen",
      });
    },
  },
  watch: {
    "uitvoering.PartijId": function (oldval, newval) {
      this.getKeuringen();
      if (oldval != null && newval != null) {
        this.uitvoering.PlanningId = null;
      }
      this.refreshKeurders();
      if (oldval != null && newval != null) {
        this.uitvoering.KeurderId = null;
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
