<template>
  <div>
    <v-select
      v-model="content"
      :items="projecten"
      label="Project"
      item-text="Naam"
      item-value="Id"
      @change="handleInput"
      return-object
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "SelectProject",
  props: ["value"],
  data: () => ({
    content: {
      Id: parseInt(localStorage.getItem("ProjectId")),
      Naam: "Loading....",
    },
    projecten: [],
  }),
  methods: {
    loadData: function () {
      let sessionprjid = parseInt(localStorage.getItem("ProjectId"));
      this.$api.pProjectenGet().then((response) => {
        this.projecten = response.data;

        this.handleInput();
        if (sessionprjid) {
          this.content = this.projecten.find((c) => c.Id == sessionprjid);
          if (this.content == null && this.projecten.length > 0) {
            this.content = this.projecten[0];
          }
          this.handleInput();
        } else {
          if (this.projecten.length > 0) {
            this.content = this.projecten[0];
            this.handleInput();
          }
        }
      });
    },
    handleInput() {
      this.$emit("input", this.content);
    },
  },
  watch: {
    content: function () {
      if (this.content && this.content.Id)
        localStorage.setItem("ProjectId", this.content.Id);
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style scoped>
</style>