<template>
  <v-container>
    <v-card v-if="$route.query.token == null">
      <v-card-title>Wachtwoord herstellen</v-card-title>
      <v-card-text>
        <div class="reset-request">
          <v-form ref="formreset" v-model="resetvalid">
            Vul uw e-mailadres in, u ontvangt een e-mail met instructie om een nieuw
            wachtwoord aan te maken.
            <v-text-field
              type="email"
              v-model="email"
              maxlength="250"
              id="email"
              name="email"
              autofocus
              label="E-mailadres"
              prepend-icon="mdi-email"
              required
              :rules="[
                (v) => !!v || 'Vul uw e-mailadres in.',
                (v) => /.+@.+\..+/.test(v) || 'Vul een geldig e-mailadres in.',
              ]"
            />
          </v-form>
          <v-alert outlined type="info" text v-show="mailverzonden"
            >Als dit e-mailadres een bekend is bij Keuringsmonitor.nl dan is er een mail
            met wachtwoordherstel-link verzonden.</v-alert
          >
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn type="submit" @click="requestReset()" color="primary"
          >Herstel wachtwoord</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card v-if="$route.query.token">
      <v-card-title>Nieuw wachtwoord instellen</v-card-title>
      <v-card-text>
        <div>
          <v-text-field
            label="Gebruikersnaam"
            disabled
            v-model="$route.query.loginname"
            prepend-icon="mdi-account-circle"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            label="Nieuw wachtwoord"
            type="password"
            v-model="newpw"
            maxlength="100"
            autofocus
            prepend-icon="mdi-lock"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            label="Herhaal wachtwoord"
            type="password"
            v-model="newpwr"
            maxlength="100"
            autofocus
            prepend-icon="mdi-lock"
          ></v-text-field>
        </div>
        <v-alert outlined type="error" text v-if="errormessage">{{
          errormessage
        }}</v-alert>
        <v-alert outlined type="warning" text v-if="message">{{ message }}</v-alert>
        <v-alert outlined type="info" text v-if="success"
          >Het wachtwoord is aangepast. Klik
          <router-link to="/Login">hier</router-link> om in te loggen.
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn type="submit" @click="reset()" color="primary">Wachtwoord instellen</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "PasswordReset",
  data() {
    return {
      email: "",
      errormessage: "",
      message: "",
      newpw: "",
      newpwr: "",
      success: false,
      resetvalid: true,
      mailverzonden: false,
    };
  },
  methods: {
    loaddata: function () {},
    requestReset: function () {
      if (this.$refs.formreset.validate()) {
        this.$api
          .RequestPasswordReset({ Email: this.email })
          .then((response) => {
            if (response.data == "Ok") {
              this.mailverzonden = true;
            } else {
              alert(response.data);
            }
          })
          .catch(() => {
            alert("Herstel wachtwoord mislukt.");
          });
      }
    },
    reset: function () {
      if (this.newpw == this.newpwr) {
        this.$api
          .PasswordReset({
            Token: this.$route.query.token,
            NewPassword: this.newpw,
          })
          .then((response) => {
            if (response.data == "Ok") {
              this.success = true;
              this.message = "";
              this.errormessage = "";
            } else {
              this.success = false;
              this.errormessage = response.data;
              this.message = "";
            }
          })
          .catch((e) => {
            this.success = false;
            this.errormessage = "Herstel wachtwoord mislukt." + e;
            this.message = "";
          });
      } else {
        this.success = false;
        this.errormessage = "Wachtwoord en herhaald wachtwoord zijn niet gelijk.";
        this.message = "";
      }
    },
  },
  created: function () {
    this.loaddata();
  },
};
</script>

<style scoped>
.login-error {
  color: red;
}
</style>
