<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="col in columns" :key="col.fieldname" class="text-left">
              {{ col.displayname }}
            </th>
            <th class="text-center" v-if="orderindex">Volgorde</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(c, i) in item" :key="i">
            <td
              v-for="(cl, j) in columns"
              :key="i.toString() + '-' + j.toString()"
            >
              {{ item[i][cl.fieldname] }}
            </td>
            <td class="text-center" v-if="orderindex">
              <v-icon v-show="i > 0" @click="sort(item[i], 'up')"
                >mdi-arrow-up-bold</v-icon
              >
              <v-icon
                v-show="i < item.length - 1"
                @click="sort(item[i], 'down')"
                >mdi-arrow-down-bold</v-icon
              >
            </td>
            <td>
              <v-icon @click="edit(item[i])" small title="Bewerken"
                >mdi-pencil-outline</v-icon
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-model="editMode" max-width="600px"
      ><v-card v-if="editclone">
        <v-toolbar color="primary" dark
          ><span v-show="!editclone.Id">Toevoegen</span
          ><span v-show="editclone.Id">Bewerken</span></v-toolbar
        >
        <v-card-text>
          <slot v-bind:item="editclone"></slot>
        </v-card-text>
        <v-card-actions>
          <v-btn
            icon
            @click="deleteItem()"
            color="red"
            title="Verwijderen"
            v-show="
              editclone.CanDelete == null ||
              editclone.CanDelete == undefined ||
              editclone.CanDelete == true
            "
            ><v-icon>mdi-delete-outline</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="save()">Opslaan</v-btn>
          <v-btn text @click="editMode = false">Annuleren</v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
    <v-btn fab small title="Nieuw item toevoegen" @click="nieuw()"
      ><v-icon>mdi-plus</v-icon></v-btn
    >
  </div>
</template>

<script>
export default {
  name: "CrudComponent",
  props: { columns: Array, selectdata: Array, orderindex: Boolean },
  data: () => ({ editMode: false, editclone: null, editindex: null }),
  methods: {
    loadData: function () {
      this.$emit("GetData");
    },
    edit: function (item) {
      this.editindex = this.selectdata.indexOf(item);
      this.editMode = true;
      this.editclone = JSON.parse(JSON.stringify(item)); // Bewerk een clone van het object (ivm cancel)
    },
    save: function () {
      this.editMode = false;
      if (this.editindex >= 0)
        this.selectdata.splice(this.editindex, 1, this.editclone);
      else this.selectdata.push(this.editclone);
      this.editindex = null;
    },
    nieuw: function () {
      this.editindex = -1;
      this.editMode = true;
      this.editclone = {
        Id: null,
        CanDelete: true,
        Deleted: false,
        OrderIndex: this.maxOrderindex() + 1,
      };
    },
    deleteItem: function () {
      this.$confirm("Weet je zeker dat je dit item wilt verwijderen?", {
        title: "Verwijderen",
      }).then((res) => {
        if (res) {
          this.editclone.Deleted = true;
          this.save();
        }
      });
    },
    maxOrderindex: function () {
      var max = 0;
      for (var i = 0; i < this.selectdata.length; i++) {
        if (
          this.selectdata[i].OrderIndex &&
          this.selectdata[i].OrderIndex > max
        )
          max = this.selectdata[i].OrderIndex;
      }
      return max;
    },
    sort: function (item, dir) {
      var idx = this.selectdata.indexOf(item);
      var ander =
        dir == "up" ? this.selectdata[idx - 1] : this.selectdata[idx + 1];
      var idxander = this.selectdata.indexOf(ander);
      var tmpidx = item.OrderIndex;
      item.OrderIndex = ander.OrderIndex;
      ander.OrderIndex = tmpidx;
      this.selectdata[idx] = this.selectdata.splice(
        idxander,
        1,
        this.selectdata[idx]
      )[0];
    },
  },
  watch: {},
  computed: {
    item: function () {
      return this.selectdata.filter(function (i) {
        return (
          i.Deleted == null || i.Deleted == undefined || i.Deleted == false
        );
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
</style>