<template>
  <v-container>
    <v-row class="justify-center">
      <v-img src="/keuringsmonitor_logo.png" max-width="500" contain class="mt-10 mb-10">
      </v-img>
    </v-row>
    <p>
      In de bouw zijn veel verschillende partijen betrokken bij het uitvoeren en
      vastleggen van kwaliteitskeuringen. Zij moeten op verschillende momenten en met
      verschillende frequenties keuringen doen. De keuringsmonitor brengt structuur en
      overzicht in het keuringsproces. <br />
      <br />
      De keuringsmonitor maakt het makkelijk om:
    </p>
    <ul>
      <li>keuringen te plannen voor verschillende partijen</li>
      <li>vast te leggen welke keuringen uitgevoerd zijn</li>
      <li>de voortgang van het keuringsproces te bewaken</li>
      <li>alle partijen te motiveren en scherp te houden</li>
    </ul>
    <p>
      <br />Keuringsmonitor is ontwikkeld door
      <a href="https://heidekracht.nl" target="_blank">Heidekracht BV</a> in samenwerking
      met
      <a href="https://bouwkwaliteitcoach.nl" target="_blank">Bouwkwaliteitcoach.nl</a>.
      <br />
    </p>
    <v-card width="500" class="mt-12">
      <v-card-title>
        <h2>Inloggen</h2>
      </v-card-title>
      <v-card-text>
        <v-form onsubmit="return false;">
          <v-text-field
            name="username"
            maxlength="200"
            label="Gebruikersnaam"
            v-model="username"
            prepend-icon="mdi-account-circle"
            autofocus
          ></v-text-field>
          <v-text-field
            name="password"
            type="password"
            maxlength="1024"
            label="Wachtwoord"
            v-model="password"
            prepend-icon="mdi-lock"
          ></v-text-field>
          <v-alert type="error" v-model="showerrormessage" dismissible class="mt-4">{{
            errormessage
          }}</v-alert>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn to="PasswordReset" small>Wachtwoord vergeten</v-btn>
        <v-spacer />
        <v-btn type="submit" @click="login()" color="primary">Inloggen</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loginOk: false,
      loginFailed: false,
      username: "",
      password: "",
      settings: null,
      errormessage: null,
      showerrormessage: false,
    };
  },
  methods: {
    loaddata: function () {
      this.$api.Logout().then(() => {
        this.$root.$emit("login");
      });
    },
    login: function () {
      this.$api
        .Login({ Username: this.username, Password: this.password })
        .then(() => {
          // Inloggen gelukt
          this.$root.$emit("login");
          // Doorsturen naar ReturnUrl
          const url = this.$route.query.ReturnUrl;
          if (url) {
            this.$router.push(url);
          } else {
            this.$router.push({ name: "home" });
          }
        })
        .catch(() => {
          this.errormessage = "Inloggen mislukt.";
          this.showerrormessage = true;
        });
    },
  },
  watch: {
    username: function () {
      this.showerrormessage = false;
    },
    password: function () {
      this.showerrormessage = false;
    },
  },
  created: function () {
    this.loaddata();
  },
};
</script>

<style scoped>
p {
  line-height: 1.8;
}
</style>
