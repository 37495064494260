import Vue from 'vue'
import VueRouter from 'vue-router'
import ApiHandler from '@/ApiHandler'
import Login from '@/components/useradmin/Login.vue'
import PasswordReset from '@/components/useradmin/PasswordReset.vue'
import Activate from '@/components/useradmin/Activate.vue'
import Home from '@/components/HelloWorld.vue'
import BeheerFrequenties from '@/components/beheerfrequenties.vue'
import BeheerFrequentie from '@/components/beheerfrequentie.vue'
import UitgevoerdeKeuringen from '@/components/uitgevoerdekeuringen.vue'
import UitgevoerdeKeuring from '@/components/uitgevoerdekeuring.vue'
import BeheerProjecten from '@/components/beheerprojecten.vue'
import GrafiekTotaal from '@/components/grafiektotaal.vue'
import GrafiekProject from '@/components/grafiekproject.vue'
import VisualistatieR from '@/components/visualisatieR.vue'
import RapportSamenstellen from '@/components/rapportsamenstellen.vue'
import Rapport from '@/components/rapport.vue'
import UitvoeringBulk from '@/components/uitvoeringbulk.vue'
import Users from '@/components/useradmin/Users.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: { auth: true }
    },
    {
        path: '/',
        name: 'homelogin',
        component: Login,
        meta: { auth: false }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { auth: false }
    },
    {
        path: '/passwordreset',
        name: 'passwordreset',
        component: PasswordReset,
        meta: { auth: false }
    },
    {
        path: '/activeer',
        name: 'activeer',
        component: Activate,
        meta: { auth: false }
    },
    {
        path: '/beheerfrequenties',
        name: 'beheerfrequenties',
        component: BeheerFrequenties,
        meta: { auth: true }
    },
    {
        path: '/beheerfrequenties/nieuw',
        name: 'beheerfrequentienieuw',
        component: BeheerFrequentie,
        meta: { auth: true }
    },
    {
        path: '/beheerfrequenties/bewerken/:id',
        name: 'beheerfrequentieedit',
        component: BeheerFrequentie,
        meta: { auth: true }
    }, {
        path: '/uitgevoerdekeuringen',
        name: 'uitgevoerdekeuringen',
        component: UitgevoerdeKeuringen,
        meta: { auth: true }
    }, {
        path: '/uitgevoerdekeuringen/bulkinvoer',
        name: 'uitvoeringbulk',
        component: UitvoeringBulk,
        meta: { auth: true }
    },
    {
        path: '/uitgevoerdekeuringen/:id',
        name: 'uitgevoerdekeuring',
        component: UitgevoerdeKeuring,
        meta: { auth: true }
    },
    {
        path: '/projecten/beheer',
        name: 'beheerprojecten',
        component: BeheerProjecten,
        meta: { auth: true }
    },
    {
        path: '/grafiektotaal',
        name: 'grafiektotaal',
        component: GrafiekTotaal,
        meta: { auth: true }
    },
    {
        path: '/grafiekproject',
        name: 'grafiekproject',
        component: GrafiekProject,
        meta: { auth: true }
    },
    {
        path: '/visualisatie',
        name: 'visualisatier',
        component: VisualistatieR,
        meta: { auth: true }
    },
    {
        path: '/Rapportage/:code',
        name: 'rapport',
        component: Rapport,
        meta: { auth: true }
    },
    {
        path: '/RapportagePerm/:code',
        name: 'rapportperm',
        component: Rapport,
        meta: { auth: false }
    },
    {
        path: '/Rapportage',
        name: 'rapporsamenstellen',
        component: RapportSamenstellen,
        meta: { auth: true }
    },
    {
        path: '/Beheer/Gebruikers',
        name: 'users',
        component: Users,
        meta: { auth: true }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.auth)) {
        // Authenticatie vereist. Check of session-cookie nog geldig is.
        var auth = false;
        (new ApiHandler()).Authenticated() // Vraag server of sessie-id nog geldig is.
            .then((response) => {
                auth = (response.data.length > 0);
                if (auth) {
                    // Sessie-id is nog geldig
                    next();
                }
                else {
                    // Sessie-id is verlopen => inlogscherm
                    next({ name: 'login', query: { ReturnUrl: to.fullPath } });
                }
            })
            .catch(() => {
                // Call naar Authenticated faalt => inlogscherm
                next({ name: 'login', query: { ReturnUrl: to.fullPath } });
            });
    }
    else {
        next();
    }
});

export default router
