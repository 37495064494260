<template>
  <v-container>
    <select-project v-model="project"></select-project>
    <h1>Totaal uitgevoerde keuringen</h1>

    <v-row>
      <v-col cols="9">
        <v-progress-linear indeterminate v-show="loading" class="mt-4 mb-6"></v-progress-linear>
        <GrafiekUitvoering ref="grafiek"></GrafiekUitvoering>
      </v-col>
      <v-col cols="3">
        <h2>Periode</h2>
        <v-radio-group v-model="rapportperiodeId">
          <v-radio
            v-for="(rp, i) in rapportperiodes"
            :key="i"
            :value="rp.Id"
            :label="rp.Naam"
          ></v-radio>
        </v-radio-group>
        <h2>Scope</h2>
        <v-radio-group v-model="scope">
          <v-radio :value="0" label="alleen totaal"></v-radio>
          <v-radio :value="1" label="totaal met partijen"></v-radio>
          <v-radio :value="2" label="per partij"></v-radio>
          <v-select
            v-model="partijId"
            :readonly="scope != 2"
            v-show="scope == 2"
            :items="partijen"
            label="Partij"
            item-text="Naam"
            item-value="Id"
          >
          </v-select>
          <v-radio
            :value="3"
            label="totaal met kenmerk"
            v-show="project && project.KenmerkEnabled"
          ></v-radio>
          <v-radio
            :value="4"
            label="per kenmerk afzonderlijk"
            v-show="project && project.KenmerkEnabled"
          ></v-radio>
          <v-select
            v-model="kenmerkId"
            :readonly="scope != 4"
            v-show="scope == 4 && project && project.KenmerkEnabled"
            :items="kenmerken"
            label="Kenmerk"
            item-text="Naam"
            item-value="Id"
          ></v-select>
          <v-radio
            :value="5"
            label="per keuringstype"
            v-show="project && project.KeuringstypeEnabled"
          ></v-radio> </v-radio-group
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
import SelectProject from "@/components/selectproject.vue";
import GrafiekUitvoering from "@/charts/grafiekuitvoering.vue";

export default {
  name: "GrafiekTotaal",
  components: {
    SelectProject,
    GrafiekUitvoering,
  },
  data: () => ({
    project: null,
    partijen: [],
    partijId: null,
    kenmerken: [],
    kenmerkId: null,
    rapportperiodeId: 1,
    rapportperiodes: [],
    scope: 0, // 0 = Alleen totaal, 1 = Totaal met partijen, 2 = Per partij, 3 = Totaal met kenmerk, 4 = Per kenmerk, 5 = Per keuringstype
    loading: false,
  }),
  methods: {
    loadData: function () {
      //this.refreshPartijen();
      this.$api.pKenmerkenGet().then((response) => {
        this.kenmerken = response.data;
        if (this.kenmerken.length > 0) this.kenmerkId = this.kenmerken[0].Id;
      });
      this.$api.pRapportPeriodeGet().then((response) => {
        this.rapportperiodes = response.data;
      });
    },
    refreshPartijen: function () {
      this.$api
        .pPartijenGet({ ProjectId: this.project.Id })
        .then((response) => {
          this.partijen = response.data;
          if (this.partijen.length > 0) this.partijId = this.partijen[0].Id;
        });
    },
    refreshChart: function () {
      switch (this.scope) {
        case 0:
        case 1:
        case 2:
          this.loading = true;
          this.$api
            .Grafiek_pGrafiekUitgevoerdeKeuringen({
              ProjectId: this.project.Id,
              AlleenTotaal: this.alleenTotaal,
              FilterPartijId: this.scope == 2 ? this.partijId : null,
              RapportPeriodeId: this.rapportperiodeId,
            })
            .then((response) => {
              this.convertData(response.data);
              this.loading = false;
            });
          break;
        case 3:
        case 4:
          this.loading = true;
          this.$api
            .Grafiek_pGrafiekUitgevoerdeKeuringenPerKenmerk({
              ProjectId: this.project.Id,
              FilterKenmerkId: this.scope == 4 ? this.kenmerkId : null,
              RapportPeriodeId: this.rapportperiodeId,
            })
            .then((response) => {
              this.convertData(response.data);
              this.loading = false;
            });
          break;
        case 5:
          this.loading = true;
          this.$api
            .Grafiek_pGrafiekUitgevoerdeKeuringenPerKeuringstype({
              ProjectId: this.project.Id,
              RapportPeriodeId: this.rapportperiodeId,
            })
            .then((response) => {
              this.convertData(response.data);
              this.loading = false;
            });
          break;
      }
    },
    convertData: function (rawdata) {
      this.$refs.grafiek.convertData(rawdata);
    },
  },
  computed: {
    alleenTotaal: function () {
      return this.scope === 0;
    },
  },
  watch: {
    project: function () {
      this.refreshChart();
      this.refreshPartijen();
    },
    scope: function () {
      this.refreshChart();
    },
    partijId: function () {
      if (this.scope == 2) this.refreshChart();
    },
    kenmerkId: function () {
      if (this.scope == 4) this.refreshChart();
    },
    rapportperiodeId: function () {
      this.refreshChart();
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
</style>