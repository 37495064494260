<template>
  <v-container>
    <h1>Overzicht geplande en uitgevoerde keuringen</h1>
    <select-project v-model="project"></select-project>
    <v-row no-gutters>
      <v-icon @click="weekterug">mdi-arrow-left-bold</v-icon>
      Week {{ peildatum | moment("W - YYYY") }}
      <v-icon @click="weekverder">mdi-arrow-right-bold</v-icon>
      <v-icon @click="dezeweek" title="Deze week">mdi-calendar</v-icon>
      <v-icon @click="recalc" title="Herberekenen" v-show="false"
        >mdi-calculator</v-icon
      >
      <v-progress-circular
        indeterminate
        color="primary"
        size="22"
        v-show="recalcing"
      ></v-progress-circular>
    </v-row>
    <v-row no-gutters
      ><v-radio-group row v-model="periode"
        ><v-radio label="Kort" value="K"></v-radio
        ><v-radio label="Middenlang" value="M"></v-radio
        ><v-radio label="Alles" value="A"></v-radio>
      </v-radio-group>
    </v-row>
    <BlokVis
      v-if="project"
      :projectid="project.Id"
      :peildatum="peildatum"
      :wekenterug="wekenterug"
      :wekenvooruit="wekenvooruit"
    ></BlokVis>
    <BlokVisLegenda></BlokVisLegenda>
  </v-container>
</template>

<script>
import SelectProject from "@/components/selectproject.vue";
import moment from "moment";
import BlokVis from "@/components/blokvis.vue";
import BlokVisLegenda from "@/components/blokvislegenda.vue";

export default {
  name: "Blokvis",
  components: {
    SelectProject,
    BlokVis,
    BlokVisLegenda,
  },
  data: () => ({
    project: null,
    peildatum: new moment(),
    imageurl: null,
    data: [],
    weken: [],
    weekfilter: [],
    selectedWeek: null,
    recalcing: false,
    periode: "M",
    wekenterug: -10,
    wekenvooruit: 10,
  }),
  methods: {
    loadData: function () {},
    weekverder: function () {
      this.peildatum = this.peildatum.add(1, "weeks").clone();
    },
    weekterug: function () {
      this.peildatum = this.peildatum.add(-1, "weeks").clone();
    },
    dezeweek: function () {
      this.peildatum = new moment();
    },
    recalc: function () {
      this.recalcing = true;
      this.$cmdapi.BlokvisRecalc({ async: false }).then(() => {
        this.recalcing = false;
        this.peildatum = this.peildatum.clone();
      });
    },
  },
  watch: {
    periode: function (val) {
      switch (val) {
        case "K":
          this.wekenterug = -5;
          this.wekenvooruit = 5;
          break;
        case "M":
          this.wekenterug = -10;
          this.wekenvooruit = 10;
          break;
        case "A":
          this.$api
            .Blokvis_pBlokvisGetProjectPeriode({ ProjectId: this.project.Id })
            .then((response) => {
              this.wekenterug = response.data[0].WekenTerug;
              this.wekenvooruit = response.data[0].WekenVooruit;
            });
          break;
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
</style>
