<template>
  <LijnGrafiek
    :chartdata="chartdata"
    :options="options"
    class="Grafiek"
    ref="Chart"
    :height="200"
  ></LijnGrafiek>
</template>

<script>
import LijnGrafiek from "@/charts/lijngrafiek.vue";
export default {
  name: "GrafiekUitvoering",
  props: [],
  components: {
    LijnGrafiek,
  },
  data: () => ({
    chartdata: {
      datasets: [],
    },
    options: {
      legend: {
        display: true,
        position: "bottom",
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              unit: "week",
              tooltipFormat: "WW - YYYY",
              displayFormats: {
                week: "WW",
              },
            },
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
            },
          },
          {
            type: "time",
            time: {
              unit: "month",
              unitStepSize: 1,
              isoWeekday: true,
              displayFormats: {
                month:"DD-MM-'YY"
              },
            },
            gridLines: {
              display: true,
              drawOnChartArea: true,
            },
            ticks: {
              beginAtZero: true,
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Aantal uitgevoerde keuringen cummulatief",
            },
            ticks: { beginAtZero: true},
            gridLines: {
              drawBorder: true,
              display: true,
              lineWidth: 1,
            },
          },
        ],
      },
    },
  }),
  methods: {
    loadData: function () {},
    convertData: function (rawdata) {
      var backcolors = [
        "rgba(40,76,190, 0.8)",
        "rgba(2,199,120, 0.8)",
        "rgba(255,109,110, 0.8)",
        "rgba(251,239,89, 0.8)",
        "rgba(127,147,216, 0.8)",
        "rgba(100,220,175, 0.8)",
        "rgba(231,30,20, 0.8)",
        "rgba(61,160,254, 0.8)",
        "rgba(1,104,109, 0.8)",
        "rgba(254,159,51, 0.8)",
        "rgba(151,102,230, 0.8)",
        "rgba(50,210,208, 0.8)",
        "rgba(191,191,191, 0.8)",
        "rgba(232,60,159, 0.8)",
        "rgba(30,55,145, 0.8)",
        "rgba(174,23,16, 0.8)",
      ];
      var bordercolors = [
        "rgba(40,76,190, 1.0)",
        "rgba(2,199,120, 1.0)",
        "rgba(255,109,110, 1.0)",
        "rgba(251,239,89, 1.0)",
        "rgba(127,147,216, 1.0)",
        "rgba(100,220,175, 1.0)",
        "rgba(231,30,20, 1.0)",
        "rgba(61,160,254, 1.0)",
        "rgba(1,104,109, 1.0)",
        "rgba(254,159,51, 1.0)",
        "rgba(151,102,230, 1.0)",
        "rgba(50,210,208, 1.0)",
        "rgba(191,191,191, 1.0)",
        "rgba(232,60,159, 1.0)",
        "rgba(30,55,145, 1.0)",
        "rgba(174,23,16, 1.0)",
      ];
      this.chartdata.datasets = [];
      if (rawdata.length > 0) {
        var lastNaam = rawdata[0].Naam;
        var data = [];
        for (var i = 0; i < rawdata.length; i++) {
          if (lastNaam != rawdata[i].Naam) {
            this.chartdata.datasets.push({
              label: this.alleenTotaal ? "Totaal" : lastNaam,
              type: "line",
              data: data,
              backgroundColor:
                backcolors[this.chartdata.datasets.length % backcolors.length],
              borderColor:
                bordercolors[
                  this.chartdata.datasets.length % bordercolors.length
                ],
              borderWidth: 0,
              order: this.chartdata.datasets.length,
              //lineTension: 0,
              steppedLine: true,
            });
            data = [];
            lastNaam = rawdata[i].Naam;
          }
          data.push({ t: rawdata[i].Datum, y: rawdata[i].AantalKeuringen });
        }
        if (data.length > 0) {
          this.chartdata.datasets.push({
            label: this.alleenTotaal
              ? "Totaal"
              : rawdata[rawdata.length - 1].Naam,
            type: "line",
            data: data,
            backgroundColor:
              backcolors[this.chartdata.datasets.length % backcolors.length],
            borderColor:
              bordercolors[
                this.chartdata.datasets.length % bordercolors.length
              ],
            borderWidth: 0,
            //lineTension: 0,
            steppedLine: true,
            order: this.chartdata.datasets.length,
          });
        }
      }
      this.$refs.Chart.update();
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
</style>