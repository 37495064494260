<template>
  <v-container>
    <h1>Gebruikersbeheer</h1>
    <div v-if="!gebruiker">
      <v-data-table
        :headers="headers"
        :items="gebruikers"
        :items-per-page="5"
        disable-pagination
        class="elevation-1"
        hide-default-footer
        @click:row="editUser"
      >
        <template v-slot:[`item.LastLogin`]="{ item }">
          <span v-if="item.LastLogin">{{
            $dayjs(item.LastLogin).format("YYYY-MM-DD HH:mm")
          }}</span>
        </template>
        <template v-slot:[`item.Active`]="{ item }">
          <span v-if="item.Active">Ja</span><span v-if="!item.Active">Nee</span>
        </template>
      </v-data-table>
      <v-btn fab title="Account toevoegen" @click="initNewUser()" color="primary"
        ><v-icon>mdi-account-plus</v-icon></v-btn
      >
    </div>
    <v-card v-if="gebruiker">
      <v-card-title
        >Account {{ gebruiker != null ? gebruiker.LoginName : "" }}</v-card-title
      >
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-text-field
            label="Inlognaam"
            max-length="200"
            v-model="gebruiker.LoginName"
            :rules="[(v) => !!v || 'Inlognaam is verplicht']"
            required
          ></v-text-field>
          <v-text-field
            label="Voornaam"
            max-length="50"
            v-model="gebruiker.FirstName"
          ></v-text-field>
          <v-text-field
            label="Tussenvoegsel"
            max-length="10"
            v-model="gebruiker.MiddleName"
          ></v-text-field>
          <v-text-field
            label="Achternaam"
            max-length="50"
            v-model="gebruiker.LastName"
            :rules="[(v) => !!v || 'Achternaam is verplicht']"
            required
          ></v-text-field>
          <v-text-field
            label="E-mail"
            max-length="250"
            v-model="gebruiker.Email"
            :rules="[
              (v) => !!v || 'E-mailadres is verplicht',
              (v) =>
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                  v
                ) || 'E-mailadres is niet geldig.',
            ]"
            required
          ></v-text-field>
          <v-checkbox label="Account actief" v-model="gebruiker.Active"></v-checkbox>
          <h2>Rollen</h2>
          <v-item-group class="mt-5">
            <v-item v-for="(r, i) in gebruiker.Role" :key="i">
              <v-checkbox
                :label="r.Name"
                v-model="r.Checked"
                class="mt-0 mb-0 pt-0 mb-0"
                dense
                hide-details
              ></v-checkbox>
            </v-item>
          </v-item-group>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Datum account aangemaakt</v-list-item-title>
                <v-list-item-subtitle>{{ gebruiker.DateInserted }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Datum laatst ingelogd</v-list-item-title>
                <v-list-item-subtitle>{{ gebruiker.DateLastLogin }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-form>
        <v-snackbar v-model="error.show" top light elevation="0">
          <v-alert outlined type="error" text>{{ error.message }}</v-alert>
        </v-snackbar>
        <v-snackbar v-model="info.show" top light elevation="0">
          <v-alert outlined type="info" text>{{ info.message }}</v-alert>
        </v-snackbar>
      </v-card-text>
      <v-card-actions>
        <v-btn text v-show="gebruiker && gebruiker.Id > 0" @click="reset"
          >Reset wachtwoord</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn @click="gebruiker = null">Annuleren</v-btn>
        <v-btn color="primary" @click="saveUser">Opslaan</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "PasswordReset",
  data: function () {
    return {
      headers: [
        {
          text: "Naam",
          value: "FullName",
        },
        {
          text: "Accountnaam",
          value: "LoginName",
        },
        {
          text: "Rol(len)",
          value: "Roles",
        },
        {
          text: "Actief",
          value: "Active",
        },
        {
          text: "E-mail",
          value: "Email",
        },
        {
          text: "Laatste login",
          value: "LastLogin",
        },
      ],
      gebruikers: [],
      gebruiker: null,
      valid: true,
      error: { show: false, message: "" },
      info: { show: false, message: "" },
    };
  },
  methods: {
    loaddata: function () {
      this.$api.UserGetList().then((response) => {
        this.gebruikers = response.data;
      });
    },
    initNewUser: function () {
      this.$api.UserInitNew().then((response) => {
        this.gebruiker = response.data[0];
      });
    },
    editUser: function (u) {
      this.$api.UserGetDetails({ UserId: u.Id }).then((response) => {
        this.gebruiker = response.data[0];
      });
    },
    saveUser: function () {
      if (this.$refs.form.validate()) {
        let isnewuser = this.gebruiker.Id == 0;
        this.$api
          .UserValidate({ json: JSON.stringify(this.gebruiker) })
          .then((response) => {
            if (response.data[0].Success) {
              this.$api
                .UserSave({ json: JSON.stringify(this.gebruiker) })
                .then((response) => {
                  // Verzend email met activatielink
                  if (isnewuser) {
                    this.gebruiker.Id = response.data[0].UserId;
                    this.$api.NewUserMail({
                      LoginId: this.gebruiker.Id,
                      Email: this.gebruiker.Email,
                    });
                  }
                  this.gebruiker = null;
                  this.loaddata();
                });
            } else {
              this.error.message = response.data[0].Msg;
              this.error.show = true;
            }
          });
      }
    },
    reset: function () {
      if (this.$refs.form.validate()) {
        this.$api
          .UserValidate({ json: JSON.stringify(this.gebruiker) })
          .then((response) => {
            if (response.data[0].Success) {
              this.$api
                .RequestPasswordReset({ Email: this.gebruiker.Email })
                .then((response) => {
                  if (response.data == "Ok") {
                    this.info.message = "Mail voor instellen wachtwoord is verzonden.";
                    this.info.show = true;
                  } else {
                    this.error.message = response.data;
                    this.error.show = true;
                  }
                })
                .catch(() => {
                  this.error.message = "Herstel wachtwoord mislukt.";
                  this.error.show = true;
                });
            } else {
              this.error.message = response.data[0].Msg;
              this.error.show = true;
            }
          });
      }
    },
  },
  created: function () {
    this.loaddata();
  },
};
</script>

<style scoped></style>
