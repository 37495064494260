var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('select-project',{model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}}),_c('h1',[_vm._v("Keuringsfrequenties")]),_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Zoek in frequenties","prepend-icon":"mdi-magnify","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(this.project)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.frequenties,"hide-default-footer":true,"disable-pagination":"","sort-by":"OrderIndex","search":_vm.search},on:{"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.Start",fn:function(ref){
var item = ref.item;
return [(item.Start)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.Start,"DD-MM-YYYY")))]):_vm._e()]}},{key:"item.Eind",fn:function(ref){
var item = ref.item;
return [(item.Einde)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.Einde,"DD-MM-YYYY")))]):_vm._e()]}},{key:"item.Inserted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.Inserted,"DD-MM-YYYY HH:mm"))+" ")]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(item.CanDelete),expression:"item.CanDelete"}],attrs:{"icon":"","title":item.CanDelete
                ? 'Verwijder frequentie'
                : 'Verwijderen niet mogelijk, keuring wordt al uitgevoerd.'},on:{"click":function($event){$event.stopPropagation();return _vm.deleteFrequentie(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)}):_vm._e(),_c('v-btn',{attrs:{"fab":"","title":"Voeg nieuwe keuringsfrequentie toe","color":"primary"},on:{"click":function($event){return _vm.add()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }