<template>
  <v-app>
    <v-navigation-drawer app clipped permanent v-if="$route.meta.auth">
      <v-list nav dense>
        <v-list-item link :to="{ name: 'home' }">
          <v-list-item-icon>
            <v-icon color="primary">mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'beheerfrequenties' }">
          <v-list-item-icon>
            <v-icon color="primary">mdi-alarm-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Beheer frequenties</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'uitgevoerdekeuringen' }">
          <v-list-item-icon>
            <v-icon color="primary">mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Uitgevoerde keuringen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'grafiektotaal' }">
          <v-list-item-icon>
            <v-icon color="primary">mdi-chart-line</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Grafiek uitgevoerd</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'grafiekproject' }">
          <v-list-item-icon>
            <v-icon color="primary">mdi-chart-line</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Projectoverzicht</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'visualisatier' }">
          <v-list-item-icon>
            <v-icon color="primary">mdi-monitor-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Visualisatie</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'rapporsamenstellen' }">
          <v-list-item-icon>
            <v-icon color="primary">mdi-file-document-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Rapportage</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'beheerprojecten' }">
          <v-list-item-icon>
            <v-icon color="primary">mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Beheer projecten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left color="secondary">
      <v-img
        src="/keuringsmonitor_logo.png"
        max-height="40"
        max-width="350"
        contain
        title="Keuringsmonitor"
      ></v-img>
      <span
        v-if="omgeving != 'prod'"
        style="background-color: red"
        class="ml-4 pl-2 pr-2"
      >
        <b>{{ omgeving }}</b
        >omgeving</span
      >
      <v-spacer></v-spacer>

      <v-menu offset-y bottom right v-if="$route.meta.auth">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item :to="{ name: 'users' }">
            <v-list-item-icon>
              <v-icon color="primary">mdi-account-multiple-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Beheer gebruikers</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'login' }">
            <v-list-item-icon>
              <v-icon color="primary">mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Uitloggen</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-footer app absolute style="line-height: 1.8">
      <div class="">
        Keuringsmonitor is een gezamenlijke ontwikkeling van
        <a href="https://heidekracht.nl" target="_blank">Heidekracht </a> en
        <a href="https://bouwkwaliteitcoach.nl" target="_blank">Bouwkwaliteitcoach.nl</a
        ><br />
        <span v-if="appinfo">Versie {{ appinfo.Versie }}</span
        ><br />
        &copy; {{ new Date().getFullYear() }}
      </div>
      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    testomgeving: true,
    omgeving: "prod",
    appinfo: null,
  }),
  methods: {
    getAppInfo: function () {
      this.$api.pAppInfo().then((response) => {
        this.appinfo = response.data[0];
      });
    },
  },
  created: function () {
    if (window.location.href.indexOf("://localhost") != -1) this.omgeving = "dev";
    if (window.location.href.indexOf("://test.") != -1) this.omgeving = "test";
    this.getAppInfo();

    this.$root.$on("login", this.getAppInfo);
    this.$root.$on("logout", this.getAppInfo);
  },
};
</script>

<style>
.v-application {
  font-family: "Open Sans", sans-serif !important;
}
</style>
