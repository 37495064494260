/*
    
 */
import axios from 'axios';

export default function CmdApiHandler(apiUrl) {
    let baseurl = "";
    if (process.env.NODE_ENV == "development")
        baseurl = 'http://localhost:8080/api/api/cmd/';
    else
        baseurl = '/api/api/cmd/';

    this.axios = axios;
    this.apiUrl = apiUrl || baseurl;
    this.config = {
        headers: { "content-type": "application/json" },
        baseURL: this.apiUrl,
        withCredentials: true,
    };
    this.configBlob = {
        headers: { "content-type": "application/json" },
        baseURL: this.apiUrl,
        withCredentials: true,
        responseType: 'blob',
    };
}


// BlokvisRecalc
CmdApiHandler.prototype.BlokvisRecalc = function (data) {
    return new Promise((resolve, reject) => {
        let config = null;
        config = this.config;
        this.axios.post('BlokvisRecalc', data, config)
            .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch(() => {
                reject('Server error');
            });
    });
}