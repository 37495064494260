import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import en from 'vuetify/es5/locale/en';
import nl from 'vuetify/es5/locale/nl'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {
            nl, en
        },
        current: 'nl'
    },
    theme: {
        themes: {
            light: {
                primary: '#31A354',
                secondary: '#B3D7B1',
            },
        },
        font: {
            family: 'Open Sans'
        }

    },

});
