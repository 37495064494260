<template>
  <div>
    <v-row
      ><v-col
        ><v-checkbox
          v-model="toonleeg"
          label="Toon lege regels"
        ></v-checkbox></v-col
    ></v-row>
    <v-row>
      <v-col cols="12">
        <div v-if="data">
          <div class="blokvissectie" v-for="sectie in data" :key="sectie.Id">
            <div class="blokvisrow weekas" v-if="weken">
              <span class="rijlabel">Weeknummer</span>
              <span class="rijcontent">
                <span
                  v-for="week in weken"
                  :key="week.Id"
                  class="weekcell"
                  :style="{ width: cellwidth }"
                  :title="week.Tooltip"
                  >{{ week.Weeknummer }}</span
                >
              </span>
            </div>
            <div class="blokvisrowheader">
              <span class="rijlabel headerlabel"></span>
              <span class="rijcontent"
                ><div class="blokvissectieheader">
                  {{ sectie.Sectienaam }}
                </div></span
              >
            </div>

            <div
              class="blokvisrow"
              v-for="rij in sectie.Rij"
              :key="rij.Id"
              v-show="toonleeg || rij.Actueel"
            >
              <span class="rijlabel contentlabel" :title="rij.Label">{{
                rij.Label
              }}</span>
              <span class="rijcontent">
                <span
                  v-for="(cell, i) in rij.Cell"
                  :key="i"
                  class="weekcell"
                  :style="{ width: cellwidth }"
                >
                  <span
                    v-show="cell.Kleur"
                    class="blok"
                    :style="{
                      'background-color': cell.Kleur,
                      'border-color': cell.KleurBorder,
                    }"
                    :title="cell.Tooltip"
                  ></span>
                </span>
              </span>
            </div>
          </div>
          <div v-if="calcdate" class="text-right calcdate">
            Berekend op {{ calcdate | moment("DD-MM-YYYY HH:mm:ss") }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "BlokvisCmp",
  components: {},
  props: ["projectid", "peildatum", "wekenterug", "wekenvooruit", "code"],
  data: () => ({
    data: [],
    weken: [],
    calcdate: null,
    toonleeg: false,
  }),
  methods: {
    loadData: function () {
      this.loadBlokvisData();
    },
    loadBlokvisData: function () {
      if (
        this.projectid &&
        this.peildatum &&
        this.wekenterug &&
        this.wekenvooruit
      ) {
        if (this.code == null || this.code == "") {
          this.$api
            .Blokvis_pBlokvisGetData({
              ProjectId: this.projectid,
              Peildatum: this.peildatum,
              WekenTerug: this.wekenterug,
              WekenVooruit: this.wekenvooruit,
            })
            .then((response) => {
              this.data = response.data;
            });
          this.$api
            .Blokvis_pBlokvisGetCalcDate({ ProjectId: this.projectid })
            .then((response) => {
              if (response.data.length > 0)
                this.calcdate = response.data[0].Datum;
              else this.calcdate = null;
            });
        } else {
          this.$api
            .Rapport_pGetBlokvis({
              Code: this.code,
              Peildatum: this.peildatum,
              WekenTerug: this.wekenterug,
              WekenVooruit: this.wekenvooruit,
            })
            .then((response) => {
              this.data = response.data;
            });
        }

        this.$api
          .Blokvis_pBlokvisGetWeken({
            Peildatum: this.peildatum,
            WekenTerug: this.wekenterug,
            WekenVooruit: this.wekenvooruit,
          })
          .then((response) => {
            this.weken = response.data;
          });
      }
    },
  },
  watch: {
    projectid: function () {
      this.loadBlokvisData();
    },
    peildatum: function () {
      this.loadBlokvisData();
    },
    wekenterug: function () {
      this.loadBlokvisData();
    },
    wekenvooruit: function () {
      this.loadBlokvisData();
    },
  },
  computed: {
    cellwidth: function () {
      return (
        (100.0 / (-1 * this.wekenterug + this.wekenvooruit + 1)).toString() +
        "%"
      );
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.blokvissectie {
  margin-bottom: 10px;
}
.blokvissectieheader {
  background-color: #d9d9d9;
  text-align: center;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
}

.blokvisrow {
  font-size: 12px;
  height: 30px;
}

.weekas {
  background-color: #fff;
}

.weekas .weekcell {
  background-color: #fff;
  cursor: pointer;
  border-right: 1px solid #ebebeb;
}

.weekcell {
  display: inline-block;
  /*width: 5%;*/
  text-align: center;
  height: 100%;
  overflow: hidden;
  line-height: 35px;
  background-color: #ebebeb;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}

.rijlabel {
  display: inline-block;
  text-align: left;
  width: 300px;
  overflow: hidden;
  height: 100%;
  line-height: 30px;
  padding-right: 5px;
}

.contentlabel {
  background-color: #ebebeb;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}

.weekas .rijlabel {
  background-color: #fff;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}

.headerlabel {
  background-color: #d9d9d9;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  height: 30px;
  line-height: 30px;
}

.rijcontent {
  display: inline-block;
  width: calc(100% - 300px);
  height: 100%;
}

.blok {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid black;
  cursor: pointer;
  border-width: 2px;
}

.blokvisrowheader {
  height: 30px;
  border-bottom: 1px solid #ebebeb;
}

.calcdate {
  font-size: 9pt;
  color: #c0c0c0;
}
</style>
