<template>
  <v-container v-if="headerinfo">
    <h1>Rapport keuringsmonitor</h1>
    <v-row>
      <v-col md="6">
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>Project</td>
                <td>{{ headerinfo.ProjectNaam }}</td>
              </tr>
              <tr>
                <td>Datum gegenereerd</td>
                <td>
                  {{ headerinfo.DatumGegenereerd | moment("DD-MM-YYYY HH:mm") }}
                </td>
              </tr>
              <tr v-show="headerinfo.ToelichtingKop">
                <td>Toelichting</td>
                <td>{{ headerinfo.ToelichtingKop }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col class="text-right">
        <a :href="headerinfo.LogoUrl" v-if="headerinfo.Logo" target="_blank">
          <v-img :src="headerinfo.Logo" contain max-width="250" class="ml-auto"></v-img>
        </a>
      </v-col>
    </v-row>
    <div class="space"></div>

    <div v-show="headerinfo.GrafiekKeuringen || headerinfo.GrafiekKeuringenPerPartij">
      <h2>Aantal uitgevoerde keuringen vanaf start project</h2>
      <div v-if="headerinfo.GrafiekKeuringen">
        <h3>Totaal</h3>
        <GrafiekUitvoering ref="grafiektotaal"></GrafiekUitvoering>
      </div>
      <div v-if="headerinfo.GrafiekKeuringenPerPartij">
        <h3>Per partij</h3>
        <GrafiekUitvoering ref="grafiekpp"></GrafiekUitvoering>
      </div>
      <div class="space"></div>
    </div>

    <div v-if="headerinfo.OverzichtKorteTermijn || headerinfo.OverzichtTotaal">
      <h2>Overzicht uitgevoerde en geplande keuringen</h2>
      <div v-if="headerinfo.OverzichtKorteTermijn">
        <h3>Korte termijn</h3>
        <v-row>
          <v-col md="6">
            <BlokVis
              :projectid="headerinfo.ProjectId"
              :peildatum="headerinfo.DatumGegenereerd"
              :wekenterug="headerinfo.OverzichtKorteTermijnWekenTerug"
              :wekenvooruit="headerinfo.OverzichtKorteTermijnWekenVooruit"
              :code="$route.params.code"
            ></BlokVis>
          </v-col>
        </v-row>
      </div>
      <div v-if="headerinfo.OverzichtTotaal">
        <h3>Totaal</h3>
        <BlokVis
          :projectid="headerinfo.ProjectId"
          :peildatum="headerinfo.DatumGegenereerd"
          :wekenterug="-9"
          :wekenvooruit="10"
          :code="$route.params.code"
        ></BlokVis>
      </div>
      <BlokVisLegenda></BlokVisLegenda>
      <div class="space"></div>
    </div>

    <div v-if="headerinfo.KeuringenPeriode">
      <h2>
        Lijst met uitgevoerde keuringen, ingevoerd van
        {{ headerinfo.KeuringenPeriodeStart | moment("DD-MM-YYYY") }} t/m
        {{ headerinfo.KeuringenPeriodeEinde | moment("DD-MM-YYYY") }}
      </h2>
      <h3>Samenvatting aantal uitgevoerde keuringen per partij</h3>
      <v-row>
        <v-col md="6">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Partij</th>
                  <th class="text-right">Aantal keuringen</th>
                </tr>
              </thead>
              <tbody class="styleLastRow">
                <tr v-for="(kpp, i) in keuringenPerPartij" :key="i">
                  <td>{{ kpp.Partij }}</td>
                  <td class="text-right">{{ kpp.AantalKeuringen }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <h3>Lijst met uitgevoerde keuringen</h3>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Partij</th>
              <th class="text-left">Keuring</th>
              <th class="text-left">Invoerdatum</th>
              <th class="text-left">Uitvoeringsdatum</th>
              <th class="text-left" v-show="headerinfo.KenmerkEnabled">Kenmerk</th>
              <th class="text-left">Opmerking</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(kp, i) in keuringenPeriode" :key="i">
              <td>{{ kp.Partij }}</td>
              <td>{{ kp.Keuring }}</td>
              <td>{{ kp.InvoerDatum | moment("DD-MM-YYYY") }}</td>
              <td>{{ kp.Uitvoeringsdatum | moment("DD-MM-YYYY") }}</td>
              <td v-show="headerinfo.KenmerkEnabled">{{ kp.Kenmerk }}</td>
              <td>{{ kp.Opmerkingen }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="space"></div>
    </div>
    <v-toolbar v-if="$route.meta.auth">
      <v-btn outlined class="ma-2" @click="send()" color="primary">
        <v-icon left>mdi-email-send-outline</v-icon>Rapport verzenden</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn outlined class="ma-2" :to="{ name: 'rapporsamenstellen' }" color="primary"
        ><v-icon left>mdi-keyboard-backspace</v-icon>Aanpassen</v-btn
      >
    </v-toolbar>
    <v-dialog v-model="verzenden" max-width="600px"
      ><v-card>
        <v-toolbar color="primary" dark>Rapportage verzenden</v-toolbar>
        <v-card-text>
          <v-text-field
            label="Verzenden naar"
            type="email"
            max-length="200"
            v-model="emailaddress"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="mailverzenden()">Verzenden</v-btn>
          <v-btn text @click="verzenden = false">Annuleren</v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
  </v-container>
</template>

<script>
import GrafiekUitvoering from "@/charts/grafiekuitvoering.vue";
import BlokVis from "@/components/blokvis.vue";
import BlokVisLegenda from "@/components/blokvislegenda.vue";

export default {
  name: "Rapport",
  components: {
    GrafiekUitvoering,
    BlokVis,
    BlokVisLegenda,
  },
  data: () => ({
    headerinfo: null,
    keuringenPerPartij: [],
    keuringenPeriode: [],
    verzenden: false,
    emailaddress: "",
    baseurl: window.location.origin,
  }),
  methods: {
    loadData: function () {
      this.loadParams(this.$route.params.code);
    },
    loadParams: function (code) {
      if (code) {
        this.$api.Rapport_pGetHeaderInfo({ Code: code }).then((response) => {
          this.headerinfo = response.data[0];

          if (this.headerinfo.GrafiekKeuringen) {
            this.$api.Rapport_pGetGrafiekTotaal({ Code: code }).then((response) => {
              this.$refs.grafiektotaal.convertData(response.data);
            });
          }

          if (this.headerinfo.GrafiekKeuringenPerPartij) {
            this.$api.Rapport_pGetGrafiekPerPartij({ Code: code }).then((response) => {
              this.$refs.grafiekpp.convertData(response.data);
            });
          }

          if (this.headerinfo.KeuringenPeriode) {
            this.$api.Rapport_pGetKeuringenPerPartij({ Code: code }).then((response) => {
              this.keuringenPerPartij = response.data;
            });
          }

          this.$api.Rapport_pGetKeuringenPeriode({ Code: code }).then((response) => {
            this.keuringenPeriode = response.data;
          });
        });
      }
    },
    send: function () {
      this.verzenden = true;
    },
    mailverzenden: function () {
      this.$api
        .Rapport_pMailRapport({
          Code: this.$route.params.code,
          BaseUrl: this.baseurl,
          Email: this.emailaddress,
        })
        .then(() => {
          this.verzenden = false;
        });
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.space {
  height: 50px;
}

.styleLastRow tr:last-child {
  font-weight: bold;
}
</style>
