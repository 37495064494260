<template>
  <v-container>
    <v-alert v-model="bezig" type="info">Rapportage wordt gegenereerd</v-alert>
    <h1>Rapportage maken</h1>
    <v-row>
      <v-col md="6">
        <v-card v-if="params">
          <v-card-title>Stel rapportage samen</v-card-title>
          <v-card-text>
            <v-select
              v-model="params.ProjectId"
              :items="projecten"
              label="Project"
              item-text="Naam"
              item-value="Id"
            ></v-select>
            <v-select
              v-model="params.RapportLogoId"
              :items="logos"
              label="Logo"
              item-text="Naam"
              item-value="Id"
            ></v-select>
            <v-row no-gutters>
              <v-checkbox v-model="params.ToelichtingKop" hide-details></v-checkbox>
              <v-text-field
                v-model="params.ToelichtingKopTekst"
                label="Toelichting in kop rapport"
                :disabled="!params.ToelichtingKop"
              ></v-text-field>
            </v-row>
            <v-checkbox
              label="Grafiek totaal uitgevoerde keuringen"
              v-model="params.GrafiekKeuringen"
            ></v-checkbox>
            <v-checkbox
              label="Grafiek totaal uitgevoerde keuringen per partij"
              v-model="params.GrafiekKeuringenPerPartij"
            ></v-checkbox>
            <v-checkbox
              label="Overzicht uitgevoerde en geplande keuringen korte termijn:"
              v-model="params.OverzichtKorteTermijn"
            ></v-checkbox>
            <v-row no-gutters v-show="params.OverzichtKorteTermijn">
              <v-col md="6">
                <v-text-field
                  class="ml-10 mt-0 pt-0 mr-12"
                  v-model="params.OverzichtKorteTermijnWekenTerug"
                  label="Aantal weken terug:"
                  suffix="weken"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters v-show="params.OverzichtKorteTermijn">
              <v-col md="6">
                <v-text-field
                  class="ml-10 mt-0 pt-0 mr-12"
                  v-model="params.OverzichtKorteTermijnWekenVooruit"
                  label="Aantal weken vooruit:"
                  suffix="weken"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-checkbox
              label="Overzicht uitgevoerde en geplande keuringen totaal"
              v-model="params.OverzichtTotaal"
            ></v-checkbox>
            <v-checkbox
              label="Lijst met uitgevoerde keuringen over periode:"
              v-model="params.KeuringenPeriode"
            ></v-checkbox>
            <v-row no-gutters v-show="params.KeuringenPeriode">
              <v-col md="6">
                <div class="ml-10 mt-0 pt-0 mr-12">
                  <date-picker
                    label="Vanaf"
                    v-model="params.KeuringenPeriodeStart"
                  ></date-picker>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters v-show="params.KeuringenPeriode">
              <v-col md="6">
                <div class="ml-10 mt-0 pt-0 mr-12">
                  <date-picker
                    label="Tot en met"
                    v-model="params.KeuringenPeriodeEinde"
                  ></date-picker>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-progress-circular
              class="mx-4"
              v-show="bezig"
              indeterminate
            ></v-progress-circular
            ><v-btn @click="genereerRapport()" :disabled="bezig" color="primary"
              >Genereer rapport</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from "./date-picker.vue";
export default {
  name: "RapportSamenstellen",
  components: { DatePicker },
  data: () => ({
    projecten: [],
    project: null,
    params: null,
    code: null,
    bezig: false,
    logos: [],
  }),
  methods: {
    loadData: function () {
      this.loadParams();
      this.loadProjecten();
    },
    loadProjecten: function () {
      this.$api.pProjectenGet().then((response) => {
        this.projecten = response.data;
      });
      this.$api.Rapport_pGetLogos().then((response) => {
        this.logos = response.data;
      });
    },
    loadParams: function () {
      this.$api.Rapport_pDefaultParamsGet().then((response) => {
        this.params = response.data[0];
        // Selecteer standaard het projectId uit de sessie
        let sessionprjid = parseInt(localStorage.getItem("ProjectId"));
        if (sessionprjid) this.params.ProjectId = sessionprjid;
      });
    },
    genereerRapport: function () {
      // Rapport opslaan
      this.bezig = true;
      this.$api.Rapport_pSaveParams(this.params).then((response) => {
        this.code = response.data[0].Code;
        this.$api.Rapport_pGenereerRapport({ Code: this.code }).then(() => {
          // Open rapport
          this.bezig = false;
          this.$router.push({ name: "rapport", params: { code: this.code } });
        });
      });
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
