<template>
  <v-container>
    <h1>Beheer projecten</h1>

    <v-card v-show="!project">
      <v-card-title>Projecten</v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item v-for="(p, i) in projecten" :key="i" @click="project = p">
            <v-list-item-icon><v-icon>mdi-folder-outline</v-icon></v-list-item-icon>
            <v-list-item-title>{{ p.Naam }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions
        ><v-btn @click="newProject()" color="primary"
          ><v-icon left>mdi-plus</v-icon> Nieuw project</v-btn
        ></v-card-actions
      >
    </v-card>

    <v-card v-if="projectdetails" class="mb-5 mt-5" flat v-show="project">
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-expansion-panels v-model="expantionpanel" accordion hover light>
            <v-expansion-panel>
              <v-expansion-panel-header color="#E5E5E5"
                >Naam en code</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-text-field
                  label="Naam"
                  v-model="projectdetails.Naam"
                  maxlength="500"
                  required
                  :rules="[(v) => !!v || 'Projectnaam is verplicht']"
                ></v-text-field>
                <v-text-field
                  label="Code"
                  v-model="projectdetails.Code"
                  maxlength="3"
                  required
                  :rules="[(v) => !!v || 'Projectcode is verplicht']"
                ></v-text-field>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-show="false">
              <v-expansion-panel-header color="#E5E5E5"
                >Project opties</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-radio-group
                  label="Keuringstype"
                  v-model="projectdetails.KeuringstypeEnabled"
                  row
                  ><v-radio label="Ja" :value="true"></v-radio
                  ><v-radio label="Nee" :value="false"></v-radio></v-radio-group
                ><v-radio-group
                  label="Keurder"
                  v-model="projectdetails.KeurderEnabled"
                  row
                  v-if="false"
                  ><v-radio label="Ja" :value="true"></v-radio
                  ><v-radio label="Nee" :value="false"></v-radio
                ></v-radio-group>
                <v-radio-group label="Kenmerk" v-model="projectdetails.KenmerkEnabled" row
                  ><v-radio label="Ja" :value="true"></v-radio
                  ><v-radio label="Nee" :value="false"></v-radio
                ></v-radio-group>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header color="#E5E5E5"
                >Project partijen</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-item-group class="mt-5">
                  <v-item v-for="(pd, i) in projectdetails.Partij" :key="i">
                    <v-checkbox
                      :label="pd.Naam"
                      v-model="pd.Checked"
                      class="mt-0 mb-0 pt-0 mb-0"
                      append-icon="mdi-pencil"
                      @click:append="editPartij(pd)"
                    ></v-checkbox>
                  </v-item>
                </v-item-group>
                <v-btn fab small title="Partij toevoegen" @click="newPartij()"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header color="#E5E5E5"
                >Project categorieën</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col md="6">
                    <crud
                      v-if="crudCategorieCols && categorieen"
                      :columns="crudCategorieCols"
                      :selectdata="categorieen.Categorieen"
                      v-slot="data"
                      orderindex
                    >
                      <v-text-field
                        v-model="data.item.Naam"
                        label="Categorie"
                      ></v-text-field>
                    </crud>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>

        <v-dialog v-model="editpartij" max-width="600px"
          ><v-card>
            <v-toolbar color="primary" dark
              ><span v-show="!partij.Id">Partij toevoegen</span
              ><span v-show="partij.Id">Partij bewerken</span></v-toolbar
            >
            <v-card-text>
              <div v-show="!partij.Id" class="mt-3 mb-3">
                De nieuwe partij is selecteerbaar bij alle projecten.
              </div>
              <div v-show="partij.Id" class="mt-3 mb-3">
                Wijzigingen in de partij zijn zichtbaar bij alle projecten.
              </div>
              <v-text-field
                label="Partij naam"
                max-length="400"
                v-model="partij.Naam"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn
                icon
                @click="deletePartij()"
                color="red"
                title="Verwijder partij (bij alle projecten)"
                v-show="partij.CanDelete"
                ><v-icon>mdi-delete-outline</v-icon></v-btn
              >
              <v-spacer></v-spacer>
              <v-btn text @click="savePartij()">Opslaan</v-btn>
              <v-btn text @click="editpartij = false">Annuleren</v-btn>
            </v-card-actions>
          </v-card></v-dialog
        >

        <v-snackbar v-model="error.show" top light elevation="0">
          <v-alert outlined type="error" text>{{ error.message }}</v-alert>
        </v-snackbar>
        <v-snackbar v-model="message.show" top light elevation="0">
          <v-alert outlined type="success" text>{{ message.message }}</v-alert>
        </v-snackbar>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="saveProject()" color="primary"
          ><v-icon left>mdi-content-save</v-icon>Opslaan</v-btn
        ><v-spacer></v-spacer
        ><v-btn @click="project = null"><v-icon left>mdi-cancel</v-icon>Annuleren</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import Crud from "@/components/common/crud.vue";

export default {
  name: "BeheerProjecten",
  components: { Crud },
  data: () => ({
    project: null,
    projecten: [],
    projectdetails: null,
    valid: false,
    error: {
      show: false,
      message: "",
    },
    message: {
      show: false,
      message: "",
    },
    editpartij: false,
    selectedpartij: null,
    partij: { Id: null, Naam: "" },
    categorieen: [],
    crudCategorieCols: [{ displayname: "Categorie", fieldname: "Naam" }],
    expantionpanel: 0,
  }),
  methods: {
    loadData: function () {
      this.getProjecten();
    },
    getProject: function () {
      if (this.project != null && this.project.Id != null) {
        this.$api
          .Projectbeheer_pProjectGet({ ProjectId: this.project.Id })
          .then((response) => {
            this.projectdetails = response.data[0];
            this.getCategorieen();
          });
      } else {
        this.projectdetails = null;
      }
    },
    saveProject: function () {
      if (this.$refs.form.validate()) {
        this.error.show = false;
        this.$api.Projectbeheer_pProjectSave(this.projectdetails).then((response) => {
          if (response.data[0].Success) {
            if (response.data[0].ProjectId)
              this.project.Id = parseInt(response.data[0].ProjectId);
            if (this.categorieen.Id == null) this.categorieen.Id = this.project.Id;
            this.$api
              .Projectbeheer_pProjectCategorieenSave(this.categorieen)
              .then(() => {});

            this.refeshProjecten();
            this.project = null;
          } else {
            this.error.message = response.data[0].Message;
            this.error.show = true;
          }
        });
      }
    },
    newProject: function () {
      this.project = { Id: null, Naam: "Nieuw project" };
      this.$api.Projectbeheer_pProjectNieuw().then((response) => {
        this.projectdetails = response.data[0];
        //this.refeshProjecten();
        this.$api.Projectbeheer_pProjectCategorieenNieuw().then((response) => {
          this.categorieen = response.data[0];
        });
        this.expantionpanel = 0;
        //this.$refs.form.resetValidation();
      });
    },
    refeshProjecten: function () {
      this.getProjecten();
    },
    editPartij: function (p) {
      this.editpartij = true;
      this.selectedpartij = p;
      this.$api.pPartijGet({ PartijId: p.Id }).then((response) => {
        this.partij = response.data[0];
      });
    },
    newPartij: function () {
      this.editpartij = true;
      this.partij = { Id: null, Naam: "", Checked: false, CanDelete: false };
    },
    savePartij: function () {
      if (this.partij.Naam.length == 0) {
        alert("Naam is verplicht");
        return;
      }
      var newpartij = this.partij.Id == null;
      this.$api
        .Projectbeheer_pPartijSave({
          PartijId: this.partij.Id,
          PartijNaam: this.partij.Naam,
        })
        .then((response) => {
          this.editpartij = false;
          if (newpartij) this.projectdetails.Partij.push(response.data[0]);
          else this.selectedpartij.Naam = response.data[0].Naam;
        });
    },
    deletePartij: function () {
      var self = this;
      this.$confirm("Weet je zeker dat je deze partij wilt verwijderen?", {
        title: "Verwijderen",
      }).then((res) => {
        if (res) {
          this.$api
            .Projectbeheer_pPartijDelete({ PartijId: self.selectedpartij.Id })
            .then((response) => {
              if (response.data[0].result == "Ok") {
                self.projectdetails.Partij.pop(self.selectedpartij);
                self.editpartij = false;
                self.selectedpartij = null;
              } else alert("Verwijderen mislukt.");
            });
        }
      });
    },
    getCategorieen: function () {
      this.$api
        .Projectbeheer_pProjectCategorieenGet({ ProjectId: this.project.Id })
        .then((response) => {
          this.categorieen = response.data[0];
        });
    },
    getProjecten: function () {
      this.$api.Projectbeheer_pProjectenBeheerGet().then((response) => {
        this.projecten = response.data;
      });
    },
  },
  watch: {
    project: function () {
      this.getProject();
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
