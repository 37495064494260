<template>
  <v-container>
    <select-project v-model="project"></select-project>
    <h1>Uitgevoerde keuringen</h1>
    <h3 class="mt-6">Uitvoering toevoegen</h3>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-row class="mb-6" v-if="newitem" no-gutters>
        <v-col
          ><v-select
            v-model="newitem.PartijId"
            :items="partijen"
            label="Partij"
            item-text="Naam"
            item-value="Id"
            :rules="[(v) => !!v || 'Selecteer een partij']"
            required
          ></v-select
        ></v-col>
        <v-col v-show="project && project.KeurderEnabled">
          <v-select
            v-model="newitem.KeurderId"
            :items="keurders"
            label="Keurder"
            item-text="Naam"
            item-value="Id"
            clearable
          ></v-select
        ></v-col>
        <v-col>
          <v-select
            v-model="newitem.KeuringId"
            :items="keuringen"
            label="Keuring"
            item-text="Keuring"
            item-value="Id"
            :rules="[(v) => !!v || 'Selecteer een keuring']"
            no-data-text="Selecteer een partij met keuringen."
            required
          ></v-select
        ></v-col>
        <v-col> <date-picker v-model="newitem.Datum" label="Datum"></date-picker></v-col>
        <v-col v-show="project && project.KenmerkEnabled">
          <v-select
            v-model="newitem.KenmerkId"
            :items="kenmerken"
            label="Kenmerk"
            item-text="Naam"
            item-value="Id"
          ></v-select
        ></v-col>
        <v-col>
          <v-text-field
            v-model="newitem.Opmerking"
            maxlength="200"
            label="Opmerkingen"
          ></v-text-field
        ></v-col>
        <v-col
          ><v-btn icon @click.stop="save()" title="Uitvoering opslaan" color="primary"
            ><v-icon>mdi-content-save</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <v-text-field
      v-model="search"
      label="Zoek in uitgevoerde keuringen"
      class="mx-4"
      prepend-icon="mdi-magnify"
      single-line
    ></v-text-field>
    <v-data-table
      v-if="this.project"
      dense
      :headers="headers"
      :items="uitgevoerdekeuringen"
      :items-per-page="50"
      :hide-default-footer="false"
      class="elevation-1"
      sort-by="Inserted"
      :sort-desc="true"
      :search="search"
      @click:row="edit"
    >
      <template v-slot:[`item.Datum`]="{ item }">
        {{ item.Datum | moment("DD-MM-YYYY") }}
        <small>{{ item.Datum | moment("(WW/YY)") }}</small>
      </template>
      <template v-slot:[`item.Inserted`]="{ item }">
        {{ item.Inserted | moment("DD-MM-YYYY HH:mm") }}
      </template>

      <template v-slot:[`item.Actions`]="{ item }">
        <v-btn
          icon
          @click.stop="deleteKeuring(item)"
          title="Verwijder uitgevoerde keuring"
          ><v-icon>mdi-delete-outline</v-icon></v-btn
        >
      </template>
    </v-data-table>

    <v-card class="mt-5">
      <v-card-title>Overige opties</v-card-title>
      <v-card-text
        ><v-btn
          small
          outlined
          color="primary"
          :to="{ name: 'uitvoeringbulk' }"
          title="Bulkinvoer van uitvoeringen"
          >Bulkinvoer</v-btn
        ></v-card-text
      >
    </v-card>

    <v-snackbar v-model="message.show" top light elevation="0">
      <v-alert outlined type="success" text>{{ message.message }}</v-alert>
    </v-snackbar>
  </v-container>
</template>

<script>
import SelectProject from "@/components/selectproject.vue";
import DatePicker from "@/components/date-picker.vue";

export default {
  name: "UitgevoerdeKeuringen",
  components: {
    DatePicker,
    SelectProject,
  },
  data: () => ({
    uitgevoerdekeuringen: [],
    partijen: [],
    keuringen: [],
    kenmerken: [],
    newitem: null,
    project: null,
    valid: false,
    keurders: [],
    headers: [
      { text: "Partij", value: "Partij" },
      { text: "Keurder", value: "Keurder", align: "" },
      { text: "Keuring", value: "Keuring" },
      { text: "Datum", value: "Datum" },
      { text: "Kenmerk", value: "Kenmerk" },
      { text: "Opmerkingen", value: "Opmerking" },
      { text: "Ingevoerd", value: "Inserted" },
      { text: "", value: "Actions", sortable: false },
    ],
    search: "",
    message: {
      show: false,
      message: "",
    },
  }),
  methods: {
    loadData: function () {
      this.resetNewItem();
      this.$api.pKenmerkenGet().then((response) => {
        this.kenmerken = response.data;
      });
    },
    getPartijen: function () {
      this.$api
        .pPartijenGet({ ProjectId: this.project.Id, AlleenMetPlanning: true })
        .then((response) => {
          this.partijen = response.data;
        });
    },
    keuringenRefresh: function () {
      if (this.project != null) {
        this.$api
          .Uitvoering_pUitgevoerdeKeuringenGet({ ProjectId: this.project.Id })
          .then((response) => {
            this.uitgevoerdekeuringen = response.data;
          });
      }
    },
    edit: function (u) {
      this.$router.push({
        name: "uitgevoerdekeuring",
        params: { id: u.Id },
      });
    },
    getKeuringen: function () {
      if (this.project != null) {
        this.$api
          .Planning_pKeuringenGet({
            ProjectId: this.project.Id,
            PartijId: this.newitem.PartijId,
          })
          .then((response) => {
            this.keuringen = response.data;
          });
      }
    },
    getKeurders: function () {
      if (this.project != null) {
        this.$api
          .pKeurdersGet({
            ProjectId: this.project.Id,
            PartijId: this.newitem.PartijId,
          })
          .then((response) => {
            this.keurders = response.data;
          });
      }
    },
    resetNewItem: function () {
      this.newitem = {
        PartijId: null,
        KeuringId: null,
        Datum: new Date(),
        KenmerkId: 1,
        KeurderId: null,
      };
    },
    save: function () {
      if (this.$refs.form.validate()) {
        this.$api
          .Uitvoering_pUitgevoerdeKeuringenSave({
            UitvoeringId: null,
            PlanningId: this.newitem.KeuringId,
            Datum: this.newitem.Datum,
            PartijId: this.newitem.PartijId,
            KenmerkId: this.newitem.KenmerkId,
            Opmerking: this.newitem.Opmerking,
            KeurderId: this.newitem.KeurderId,
          })
          .then(() => {
            this.message.message = "De uitvoering is opgeslagen.";
            this.message.show = true;
            this.$cmdapi.BlokvisRecalc({ async: true });
            this.$refs.form.resetValidation();
            this.keuringenRefresh();
            this.resetNewItem();
          });
      }
    },
    deleteKeuring: function (k) {
      this.$confirm("Weet je zeker dat je deze uitgevoerde keuring wilt verwijderen?", {
        title: "Verwijderen",
      }).then((res) => {
        if (res) {
          this.$api
            .Uitvoering_pUitgevoerdeKeuringDelete({ UitvoeringId: k.Id })
            .then(() => {
              this.$cmdapi.BlokvisRecalc({ async: true });
              this.keuringenRefresh();
            });
        }
      });
    },
    setColVis: function () {
      this.headers[1].align = this.project.KeurderEnabled ? "" : " d-none";
      this.headers[4].align = this.project.KenmerkEnabled ? "" : " d-none";
    },
  },
  watch: {
    "newitem.PartijId": function (oldval, newval) {
      this.getKeuringen();
      this.newitem.KeuringId = null;
      this.getKeurders();
      if (oldval != null && newval != null) {
        this.newitem.KeurderId = null;
      }
    },
    project: function () {
      this.getPartijen();
      this.keuringenRefresh();
      this.resetNewItem();
      this.getKeurders();
      this.setColVis();
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
