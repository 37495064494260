<template>
  <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="computedDateFormattedMomentjs"
        :clearable="clearable"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        @click:clear="clear()"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      @change="datepickerchange()"
      :first-day-of-week="1"
      show-week
      locale-first-day-of-year="4"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  props: ["value", "label", "clearable"],
  data: () => ({
    date: moment(new Date()).format("YYYY-MM-DD"),
    menu1: false,
  }),
  methods: {
    handleInput() {
      this.$emit("input", this.date);
    },
    datepickerchange() {
      this.menu1 = false;
      this.handleInput();
    },
    clear() {
      this.date = null;
      this.handleInput();
    },
  },
  watch: {
    value: function() {
      if (this.value != null)
        this.date = moment(this.value).format("YYYY-MM-DD");
    }
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("DD-MM-YYYY") : "";
    },
  },
  created() {
    if (this.value) this.date = moment(this.value).format("YYYY-MM-DD");
  },
};
</script>